import React from "react";
import { useSelector } from "react-redux";

export const DownloadCSV = ({headers, data, fileName, buttonText, delimiter, icon}) => {
  const { colores } = useSelector((state) => state.ui);
  const convertToCSV = (objArray) => {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    // Convertir la cadena de encabezados en un array
    let headerSplit = headers.split('|');
    let headerLine = headerSplit.join(';');
    str += headerLine + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += delimiter;

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  };

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
	icon ?
	icon
	: 
    <button
      className={"active"}
      style={{
        marginLeft: "20px",
        color: colores.colorPrincipal,
        backgroundColor: colores.colorSecundario,
        border: "0px solid blue",
        height: '30px',
        width: '80px'
      }}
      onClick={downloadCSV}
    >
      {buttonText}
    </button>
  );
};
