/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutsideClick } from './useOutsideClick';

export const DropdownMetodosPago = ({ metodos, texto, handleMetodoPagoSelected, selectMetodoPago, editCita = false }) => {

    const { colores } = useSelector(state => state.ui);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        if (selectMetodoPago.visible) {
            setVisibility(true);
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = (index) => {
        console.log(index);
        hideDropdown();
        setSelectedIndex(index);
        handleMetodoPagoSelected(metodos[index]);
    }

    useOutsideClick(dropdownMenuRef, hideDropdown, visibility);

    useEffect(() => {
        if (!selectMetodoPago.selected) {
            if (selectedIndex !== -1) {
                setSelectedIndex(-1);
            }
        } else {
            const index = metodos.findIndex(metodo => metodo.id === selectMetodoPago.selected.id);
            if (index !== selectedIndex) {
                setSelectedIndex(index);
            }
        }
    }, [selectMetodoPago, metodos]);

    

    return (
        <div className="brb-select">
            <div
                className={`brb-select-container ${(selectMetodoPago.visible) ? 'visible' : ''} ${(visibility && !editCita) ? 'active' : ''}`}
                onClick={() => (selectMetodoPago.visible && visibility) ? hideDropdown() : showDropdown()}
            >
                <p>{(selectedIndex > -1) ? metodos[selectedIndex].nombre : texto}</p>
            </div>
            <div ref={dropdownMenuRef} className={`brb-select-options select-servicio ${visibility ? 'active' : ''}`}>
                {
                    metodos.map((metodo, idx) => (
                        <div
                            className="brb-select-option"
                            style={{ backgroundColor: colores.color6 }}
                            key={metodo.id}
                            onClick={() => {console.log('ojo: ', idx); selectItem(idx)}}
                        >
                            <p>{metodo.nombre}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
