
export const ReservasIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.788" viewBox="0 0 20 20">
            <g id="date-outline-badged" transform="translate(-2 -1)">
                <path fill={`${ color }`} id="Trazado_113" data-name="Trazado 113" className="cls-1" d="M20.182,10.376v10.17H3.212V7.212H5.03V6H3.06A1.079,1.079,0,0,0,2,7.1V20.66a1.079,1.079,0,0,0,1.061,1.1H20.333a1.079,1.079,0,0,0,1.061-1.1V9.842a4.515,4.515,0,0,1-1.212.533Z" transform="translate(0 -1.97)"/>
                <path fill={`${ color }`} id="Trazado_114" data-name="Trazado 114" className="cls-1" d="M8,14H9.212v1.212H8Z" transform="translate(-2.364 -5.121)"/>
                <path fill={`${ color }`} id="Trazado_115" data-name="Trazado 115" className="cls-1" d="M14,14h1.212v1.212H14Z" transform="translate(-4.727 -5.121)"/>
                <path fill={`${ color }`} id="Trazado_116" data-name="Trazado 116" className="cls-1" d="M20,14h1.212v1.212H20Z" transform="translate(-7.091 -5.121)"/>
                <path fill={`${ color }`} id="Trazado_117" data-name="Trazado 117" className="cls-1" d="M26,14h1.212v1.212H26Z" transform="translate(-9.455 -5.121)"/>
                <path fill={`${ color }`} id="Trazado_118" data-name="Trazado 118" className="cls-1" d="M8,19H9.212v1.212H8Z" transform="translate(-2.364 -7.091)"/>
                <path fill={`${ color }`} id="Trazado_119" data-name="Trazado 119" className="cls-1" d="M14,19h1.212v1.212H14Z" transform="translate(-4.727 -7.091)"/>
                <path fill={`${ color }`} id="Trazado_120" data-name="Trazado 120" className="cls-1" d="M20,19h1.212v1.212H20Z" transform="translate(-7.091 -7.091)"/>
                <path fill={`${ color }`} id="Trazado_121" data-name="Trazado 121" className="cls-1" d="M26,19h1.212v1.212H26Z" transform="translate(-9.455 -7.091)"/>
                <path fill={`${ color }`} id="Trazado_122" data-name="Trazado 122" className="cls-1" d="M8,24H9.212v1.212H8Z" transform="translate(-2.364 -9.061)"/>
                <path fill={`${ color }`} id="Trazado_123" data-name="Trazado 123" className="cls-1" d="M14,24h1.212v1.212H14Z" transform="translate(-4.727 -9.061)"/>
                <path fill={`${ color }`} id="Trazado_124" data-name="Trazado 124" className="cls-1" d="M20,24h1.212v1.212H20Z" transform="translate(-7.091 -9.061)"/>
                <path fill={`${ color }`} id="Trazado_125" data-name="Trazado 125" className="cls-1" d="M26,24h1.212v1.212H26Z" transform="translate(-9.455 -9.061)"/>
                <path fill={`${ color }`} id="Trazado_126" data-name="Trazado 126" className="cls-1" d="M9.606,6.848a.606.606,0,0,0,.606-.606V2.606A.606.606,0,1,0,9,2.606V6.242A.606.606,0,0,0,9.606,6.848Z" transform="translate(-2.758 -0.394)"/>
                <path fill={`${ color }`} id="Trazado_127" data-name="Trazado 127" className="cls-1" d="M18.758,6H13V7.212h5.927A4.539,4.539,0,0,1,18.758,6Z" transform="translate(-4.333 -1.97)"/>
                <path fill={`${ color }`} id="Trazado_128" data-name="Trazado 128" className="cls-1" d="M31.061,4.03A3.03,3.03,0,1,1,28.03,1a3.03,3.03,0,0,1,3.03,3.03Z" transform="translate(-9.061 0)"/>
            </g>
        </svg>
    )
}

export const ProfesionalesIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.286" height="21.286" viewBox="0 0 21.286 21.286">
            <g id="Grupo_266" data-name="Grupo 266" transform="translate(0.001)">
                <path fill={`${ color }`} id="Trazado_242" data-name="Trazado 242" className="cls-1" d="M164.348,6.7A3.352,3.352,0,1,0,161,3.352,3.355,3.355,0,0,0,164.348,6.7Zm0-6.024a2.672,2.672,0,1,1-2.672,2.672A2.675,2.675,0,0,1,164.348.679Z" transform="translate(-153.706)"/>
                <path fill={`${ color }`} id="Trazado_243" data-name="Trazado 243" className="cls-1" d="M112.759,163.688a5.126,5.126,0,0,1,5.108,4.78h-7.85a.34.34,0,1,0,0,.679h8.2a.34.34,0,0,0,.34-.34,5.8,5.8,0,0,0-11.6,0,.34.34,0,0,0,.34.34h1.359a.34.34,0,1,0,0-.679H107.65a5.126,5.126,0,0,1,5.108-4.78Z" transform="translate(-102.117 -155.627)"/>
                <path fill={`${ color }`} id="Trazado_244" data-name="Trazado 244" className="cls-1" d="M6.358,331.949l-1.863-.313-.873-1.675a.34.34,0,0,0-.6,0l-.873,1.675-1.863.313a.34.34,0,0,0-.186.573L1.42,333.87l-.278,1.869a.34.34,0,0,0,.487.354l1.691-.842,1.691.842a.34.34,0,0,0,.487-.354L5.22,333.87l1.324-1.348a.34.34,0,0,0-.186-.573Zm-1.741,1.566a.34.34,0,0,0-.094.288l.207,1.391-1.259-.627a.339.339,0,0,0-.3,0l-1.259.627.207-1.391a.339.339,0,0,0-.094-.288l-.986-1,1.387-.233a.34.34,0,0,0,.245-.178l.65-1.247.65,1.247a.34.34,0,0,0,.245.178l1.387.233Z" transform="translate(0 -314.843)"/>
                <path fill={`${ color }`} id="Trazado_245" data-name="Trazado 245" className="cls-1" d="M168.025,331.949l-1.863-.313-.873-1.675a.34.34,0,0,0-.6,0l-.873,1.675-1.863.313a.34.34,0,0,0-.186.573l1.324,1.348-.278,1.869a.34.34,0,0,0,.487.354l1.691-.842,1.691.842a.34.34,0,0,0,.487-.354l-.278-1.869,1.324-1.348a.34.34,0,0,0-.186-.573Zm-1.741,1.566a.34.34,0,0,0-.094.288l.207,1.391-1.259-.627a.339.339,0,0,0-.3,0l-1.259.627.207-1.391a.339.339,0,0,0-.094-.288l-.986-1,1.387-.233a.34.34,0,0,0,.245-.178l.65-1.247.65,1.247a.34.34,0,0,0,.245.178l1.387.233-.986,1Z" transform="translate(-154.345 -314.843)"/>
                <path fill={`${ color }`} id="Trazado_246" data-name="Trazado 246" className="cls-1" d="M329.958,332.179a.34.34,0,0,0-.267-.23l-1.863-.313-.873-1.675a.34.34,0,0,0-.6,0l-.873,1.675-1.863.313a.34.34,0,0,0-.186.573l1.324,1.348-.278,1.869a.34.34,0,0,0,.487.354l1.691-.842,1.691.842a.34.34,0,0,0,.487-.354l-.278-1.869,1.324-1.348a.339.339,0,0,0,.081-.343Zm-2.008,1.336a.34.34,0,0,0-.094.288l.207,1.391-1.259-.627a.339.339,0,0,0-.3,0l-1.259.627.207-1.391a.34.34,0,0,0-.094-.288l-.986-1,1.387-.233A.34.34,0,0,0,326,332.1l.65-1.247.65,1.247a.34.34,0,0,0,.245.178l1.387.233-.986,1Z" transform="translate(-308.69 -314.843)"/>
                <path fill={`${ color }`} id="Trazado_247" data-name="Trazado 247" className="cls-1" d="M208.745,84.96a1.54,1.54,0,0,0,1.162-.531.34.34,0,0,0-.513-.446.858.858,0,0,1-1.3,0,.34.34,0,1,0-.513.446,1.54,1.54,0,0,0,1.162.531Z" transform="translate(-198.103 -80.068)"/>
            </g>
        </svg>
    );
}

export const BuscarIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.993" height="16.271" viewBox="0 0 17.993 16.271">
            <g stroke={`${ color }`} id="Grupo_291" data-name="Grupo 291" transform="translate(-1717.224 490.845) rotate(-30)">
                <g id="Elipse_82" data-name="Elipse 82" className="cls-1" transform="translate(1730 438)">
                <circle className="cls-2" cx="5.164" cy="5.164" r="5.164"/>
                <circle className="cls-3" cx="5.164" cy="5.164" r="4.164"/>
                </g>
                <line id="Línea_117" data-name="Línea 117" className="cls-1" x2="3.204" y2="6.408" transform="translate(1737.179 447.599)"/>
            </g>
        </svg>
    );
}
export const AddIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22">
            <text fill={`${ color }`} id="_" data-name="+" className="cls-1" transform="translate(0 18)"><tspan x="0" y="0">+</tspan></text>
        </svg>
    );
}
export const CalendarIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.611" height="19.611" viewBox="0 0 19.611 19.611">
            <g id="calendar" transform="translate(-2.25 -2.25)">
                <path fill={`${ color }`} id="Trazado_106" data-name="Trazado 106" className="cls-1" d="M21.16,3.651h-2.1V2.25h-1.4v1.4H6.452V2.25h-1.4v1.4H2.95l-.7.7V21.16l.7.7H21.16l.7-.7V4.351Zm-.7,16.809H3.651V7.853H20.46Zm0-14.008H3.651v-1.4H20.46Zm-14.008,5.6h-1.4v1.4h1.4Zm-1.4,2.8h1.4v1.4h-1.4Zm1.4,2.8h-1.4v1.4h1.4Zm2.8-5.6h1.4v1.4h-1.4Zm1.4,2.8h-1.4v1.4h1.4Zm-1.4,2.8h1.4v1.4h-1.4Zm1.4-8.4h-1.4v1.4h1.4Zm2.8,2.8h1.4v1.4h-1.4Zm1.4,2.8h-1.4v1.4h1.4Zm-1.4,2.8h1.4v1.4h-1.4Zm1.4-8.4h-1.4v1.4h1.4Zm2.8,2.8h1.4v1.4h-1.4Zm1.4,2.8h-1.4v1.4h1.4Zm-1.4-5.6h1.4v1.4h-1.4Z"/>
            </g>
        </svg>
    );
}
export const ListIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.356" height="14.464" viewBox="0 0 17.356 14.464">
            <g id="list" transform="translate(-4.5 -6.75)">
                <path fill={`${ color }`} id="Trazado_107" data-name="Trazado 107" className="cls-1" d="M11.25,6.75H24.267V8.2H11.25Z" transform="translate(-2.411)"/>
                <path fill={`${ color }`} id="Trazado_108" data-name="Trazado 108" className="cls-1" d="M11.25,27H24.267v1.446H11.25Z" transform="translate(-2.411 -7.233)"/>
                <path fill={`${ color }`} id="Trazado_109" data-name="Trazado 109" className="cls-1" d="M11.25,16.875H24.267v1.446H11.25Z" transform="translate(-2.411 -3.616)"/>
                <path fill={`${ color }`} id="Trazado_110" data-name="Trazado 110" className="cls-1" d="M4.5,16.875H5.946v1.446H4.5Z" transform="translate(0 -3.616)"/>
                <path fill={`${ color }`} id="Trazado_111" data-name="Trazado 111" className="cls-1" d="M4.5,6.75H5.946V8.2H4.5Z"/>
                <path fill={`${ color }`} id="Trazado_112" data-name="Trazado 112" className="cls-1" d="M4.5,27H5.946v1.446H4.5Z" transform="translate(0 -7.233)"/>
            </g>
        </svg>
    );
}
export const SelectArrowIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.218" height="7.294" viewBox="0 0 10.218 7.294">
            <path stroke={`${ color }`} id="Trazado_186" data-name="Trazado 186" className="cls-1" d="M4417,557.766l5.094,4.475L4417,566.463" transform="translate(-557.014 4423.634) rotate(-90)"/>
        </svg>
    );
}
export const RandomIcon = ({color, bgColor}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
            <g id="Grupo_1310" data-name="Grupo 1310" transform="translate(-315.939 -461.981)">
                <rect fill={ bgColor } id="Rectángulo_735" data-name="Rectángulo 735" width="12" height="11" rx="3" transform="translate(315.939 461.981)"/>
                <path id="random" d="M4.966,13.089a.466.466,0,1,1,0-.931H5.9l1.4-1.4-1.4-1.4H4.966a.466.466,0,0,1,0-.931h1.4l1.863,1.863,1.863-1.863h.931V7.5l1.863,1.4-1.863,1.4V9.363h-.466l-1.4,1.4,1.4,1.4h.466v-.931l1.863,1.4-1.863,1.4v-.931h-.931L8.226,11.226,6.363,13.089Z" transform="translate(313.439 456.721)" fill={color} fillRule="evenodd"/>
            </g>
        </svg>
    );
}
export const CloseIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.815" height="12.909" viewBox="0 0 12.815 12.909">
            <g stroke={ color } id="Grupo_240" data-name="Grupo 240" transform="translate(-10877.79 -205.796)">
                <line id="Línea_278" data-name="Línea 278" className="cls-1" x2="11.395" y2="11.502" transform="translate(10878.5 206.5)"/>
                <line id="Línea_279" data-name="Línea 279" className="cls-1" y1="11.502" x2="11.395" transform="translate(10878.5 206.5)"/>
            </g>
        </svg>
    );
}
export const CommentsIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.546" height="18.681" viewBox="0 0 19.546 18.681">
            <g id="Grupo_381" data-name="Grupo 381" transform="translate(-905.93 -269.672)">
                <g id="chat-left-dots" transform="translate(905.93 269.672)">
                    <path id="Trazado_283" data-name="Trazado 283" d="M17.1,1.222H2.443A1.222,1.222,0,0,0,1.222,2.443V16.6l2.443-2.443a2.443,2.443,0,0,1,1.727-.716H17.1a1.222,1.222,0,0,0,1.222-1.222V2.443A1.222,1.222,0,0,0,17.1,1.222ZM2.443,0A2.443,2.443,0,0,0,0,2.443V18.071a.611.611,0,0,0,1.043.431l3.485-3.485a1.222,1.222,0,0,1,.864-.358H17.1a2.443,2.443,0,0,0,2.443-2.443V2.443A2.443,2.443,0,0,0,17.1,0Z" transform="translate(0)" fill={ color } fillRule="evenodd"/>
                    <path id="Trazado_284" data-name="Trazado 284" d="M9.193,12.472A1.222,1.222,0,1,1,7.972,11.25,1.222,1.222,0,0,1,9.193,12.472Zm4.886,0a1.222,1.222,0,1,1-1.222-1.222A1.222,1.222,0,0,1,14.08,12.472Zm4.886,0a1.222,1.222,0,1,1-1.222-1.222A1.222,1.222,0,0,1,18.966,12.472Z" transform="translate(-3.085 -5.142)" fill={ color }/>
                </g>
            </g>
        </svg>
    )
}
export const CommentsMiniIcon = ({color}) => {
    return (
        <svg id="chat-left-dots" xmlns="http://www.w3.org/2000/svg" width="11.983" height="15.453" viewBox="0 0 11.983 11.453">
            <path id="Trazado_283" data-name="Trazado 283" d="M1.5,0A1.5,1.5,0,0,0,0,1.5v9.581a.374.374,0,0,0,.639.264L2.776,9.206a.749.749,0,0,1,.53-.219h7.179a1.5,1.5,0,0,0,1.5-1.5V1.5a1.5,1.5,0,0,0-1.5-1.5Z" fill={color} fillRule="evenodd"/>
        </svg>
    );
}
export const StarIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.924" height="15.927" viewBox="0 0 16.924 15.927">
            <g id="star" transform="translate(0.508 0.5)">
                <path id="Trazado_119" data-name="Trazado 119" d="M2.851,15.39a.528.528,0,0,0,.742.589l4.364-2.243,4.363,2.243a.528.528,0,0,0,.742-.589l-.825-4.7,3.5-3.337a.553.553,0,0,0-.281-.944L10.59,5.716l-2.172-4.3a.51.51,0,0,0-.922,0l-2.171,4.3L.455,6.408a.553.553,0,0,0-.281.944l3.5,3.337-.825,4.7Z" transform="translate(-0.003 -1.122)" fillRule="evenodd"/>
            </g>
        </svg>

    );
}
export const StarSelectorIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.127" height="17.278" viewBox="0 0 18.127 17.278">
            <path id="star" d="M12.563,17.168,7.58,19.778l.951-5.527L4.5,10.336l5.571-.806L12.563,4.5l2.492,5.029,5.571.806-4.032,3.916.951,5.527Z" transform="translate(-3.5 -3.5)" stroke={color} fill={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </svg>
    );
}
export const SendSelectorIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.444" height="20.345" viewBox="0 0 19.444 20.345">
            <path id="send" d="M23.465,13.921,5.662,4.595a.78.78,0,0,0-.874.1.872.872,0,0,0-.267.848l2.4,9.131L4.489,23.781a.88.88,0,0,0,.147.748.8.8,0,0,0,.662.32.78.78,0,0,0,.364-.093l17.8-9.326a.867.867,0,0,0,0-1.509ZM6.553,22.4l1.788-6.876h7.477v-1.7H8.341L6.553,6.951l14.736,7.724Z" transform="translate(-4.461 -4.505)" fill={color} />
        </svg>
    );
}
export const TimeIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.792" height="17.793" viewBox="0 0 17.792 17.793">
            <g id="time" transform="translate(0 0)">
                <path fill={color} id="Trazado_287" data-name="Trazado 287" d="M11.146,20.043a8.9,8.9,0,1,1,8.9-8.9A8.9,8.9,0,0,1,11.146,20.043Zm0-16.522a7.625,7.625,0,1,0,7.625,7.625A7.625,7.625,0,0,0,11.146,3.521Z" transform="translate(-2.25 -2.25)"/>
                <path fill={color} id="Trazado_288" data-name="Trazado 288" d="M20.427,17.407l-3.552-3.552V7.875h1.271v5.452l3.177,3.183Z" transform="translate(-8.614 -4.698)"/>
            </g>
        </svg>
    );
}
export const DescansosIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.585" height="16.989" viewBox="0 0 17.585 16.989">
            <path fill={color} id="beach-access" d="M11.065,12.155l5.717-5.717L15.648,5.3A8.508,8.508,0,0,0,3.616,17.335L4.75,18.47l5.39-5.39,6.721,6.72h1.848ZM9.632,4.119a7.153,7.153,0,0,1,5.092,2.109l.211.211L12.6,8.769a19.505,19.505,0,0,0-2.568-2.2A10.416,10.416,0,0,0,7.1,5.05a6.244,6.244,0,0,0-.7-.168,7.17,7.17,0,0,1,3.233-.763Zm-4.881,12.5-.211-.211A7.213,7.213,0,0,1,3.194,8.086a6.253,6.253,0,0,0,.168.7,10.416,10.416,0,0,0,1.516,2.94,19.505,19.505,0,0,0,2.2,2.568Zm3.255-3.255a18.272,18.272,0,0,1-2.059-2.4c-.934-1.321-1.9-3.181-1.378-4.715,1.528-.523,3.381.438,4.7,1.365A18.311,18.311,0,0,1,11.68,9.692Z" transform="translate(-1.124 -2.812)"/>
        </svg>
    );
}
export const ServiciosIcon = ({color, width="17.792", height="20.298"}) => {
    return (
        <svg id="handbag" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17.792 20.298">
            <path fill={color} id="Trazado_290" data-name="Trazado 290" d="M15.056,1.269a2.537,2.537,0,0,0-2.537,2.537V9.515a.634.634,0,1,1-1.269,0V3.806a3.806,3.806,0,1,1,7.612,0V9.515a.634.634,0,1,1-1.269,0V3.806A2.537,2.537,0,0,0,15.056,1.269Z" transform="translate(-6.16)" fillRule="evenodd"/>
            <path fill={color} id="Trazado_291" data-name="Trazado 291" d="M5.289,12.519a.634.634,0,0,0-.632.571L3.5,21.843a1.9,1.9,0,0,0,1.895,2.094H16.842a1.9,1.9,0,0,0,1.894-2.093L17.578,13.09a.634.634,0,0,0-.63-.571H5.289Zm-1.894.444A1.9,1.9,0,0,1,5.289,11.25H16.947a1.9,1.9,0,0,1,1.894,1.713L20,21.719a3.172,3.172,0,0,1-3.156,3.486H5.394a3.172,3.172,0,0,1-3.156-3.487l1.157-8.754Z" transform="translate(-2.222 -4.907)" fillRule="evenodd"/>
        </svg>
    );
}
export const PermisosIcon = ({color}) => {
    return (
        <svg id="settings" xmlns="http://www.w3.org/2000/svg" width="21.08" height="21.651" viewBox="0 0 21.08 21.651">
            <path fill={color} id="Trazado_230" data-name="Trazado 230" d="M21.78,13.709V12.523l1.489-1.3a1.551,1.551,0,0,0,.294-1.978l-1.83-3.1a1.563,1.563,0,0,0-1.838-.7l-1.884.636A8.8,8.8,0,0,0,17,5.5l-.4-1.954a1.551,1.551,0,0,0-1.551-1.248H11.42A1.551,1.551,0,0,0,9.869,3.543L9.473,5.5a8.9,8.9,0,0,0-1.024.582L6.6,5.412a1.551,1.551,0,0,0-.5-.046,1.551,1.551,0,0,0-1.341.775l-1.83,3.1a1.551,1.551,0,0,0,.318,1.947l1.465,1.341v1.186l-1.465,1.3A1.551,1.551,0,0,0,2.938,17l1.83,3.1a1.563,1.563,0,0,0,1.838.7l1.884-.636a8.8,8.8,0,0,0,1.016.582L9.9,22.7a1.551,1.551,0,0,0,1.551,1.248h3.66A1.551,1.551,0,0,0,16.663,22.7l.4-1.954a8.9,8.9,0,0,0,1.024-.582l1.877.636a1.563,1.563,0,0,0,1.838-.7L23.565,17a1.551,1.551,0,0,0-.318-1.947Zm-1.388,5.614-2.66-.9a6.87,6.87,0,0,1-2.1,1.217l-.55,2.784H11.42l-.551-2.753a7.258,7.258,0,0,1-2.094-1.217l-2.667.868-1.83-3.1,2.109-1.861a6.9,6.9,0,0,1,0-2.427L4.279,10.018l1.83-3.1,2.66.9A6.87,6.87,0,0,1,10.87,6.6l.55-2.784h3.66l.551,2.753a7.258,7.258,0,0,1,2.094,1.217l2.667-.868,1.83,3.1-2.109,1.861a6.9,6.9,0,0,1,0,2.427l2.109,1.915Z" transform="translate(-2.715 -2.295)"/>
            <path fill={color} id="Trazado_231" data-name="Trazado 231" d="M15.9,20.555a4.606,4.606,0,1,1,3.3-1.349A4.653,4.653,0,0,1,15.9,20.555Zm0-7.754a3.032,3.032,0,1,0,2.214.887A3.032,3.032,0,0,0,15.9,12.8Z" transform="translate(-5.367 -5.077)"/>
        </svg>
    )
}
export const DotsIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3" height="13" viewBox="0 0 3 13">
            <g id="Grupo_523" data-name="Grupo 523" transform="translate(953 440) rotate(180)">
                <rect id="Rectángulo_484" data-name="Rectángulo 484" width="3" height="3" transform="translate(950 427)" fill="#524f4f"/>
                <rect id="Rectángulo_485" data-name="Rectángulo 485" width="3" height="3" transform="translate(950 432)" fill="#524f4f"/>
                <rect id="Rectángulo_486" data-name="Rectángulo 486" width="3" height="3" transform="translate(950 437)" fill="#524f4f"/>
            </g>
        </svg>

    );
}
export const FiltersIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.867" height="14.062" viewBox="0 0 19.867 14.062">
            <g id="Grupo_244" data-name="Grupo 244" transform="translate(-2 -3)">
                <path fill={ `${color}`} id="Trazado_167" data-name="Trazado 167" d="M24.838,16.314H14.06V15.25H24.838a.532.532,0,1,1,0,1.064Z" transform="translate(-3.503 -1.558)"/>
                <path fill={ `${color}`} id="Trazado_168" data-name="Trazado 168" d="M5.945,16.314H2.532a.532.532,0,1,1,0-1.064H5.945Z" transform="translate(0 -1.558)"/>
                <path fill={ `${color}`} id="Trazado_169" data-name="Trazado 169" d="M27.8,7.314H24.25V6.25H27.8a.532.532,0,1,1,0,1.064Z" transform="translate(-6.463 -0.944)"/>
                <path fill={ `${color}`} id="Trazado_170" data-name="Trazado 170" d="M13.175,7.314H2.532a.532.532,0,1,1,0-1.064H13.175Z" transform="translate(0 -0.944)"/>
                <path fill={ `${color}`} id="Trazado_173" data-name="Trazado 173" d="M21.3,8.676H18.376A1.377,1.377,0,0,1,17,7.3h0V4.376A1.377,1.377,0,0,1,18.376,3h2.93a1.376,1.376,0,0,1,1.369,1.376V7.3A1.377,1.377,0,0,1,21.3,8.676ZM18.376,4.064a.312.312,0,0,0-.312.312h0V7.3a.312.312,0,0,0,.312.312h2.93a.312.312,0,0,0,.312-.312h0V4.376a.312.312,0,0,0-.312-.312h-2.93Z" transform="translate(-4.357 0)"/>
                <path fill={ `${color}`} id="Trazado_175" data-name="Trazado 175" d="M11.11,17.676H8.186A1.377,1.377,0,0,1,6.81,16.3h0V13.376A1.377,1.377,0,0,1,8.186,12H11.11a1.377,1.377,0,0,1,1.376,1.376h0v2.93A1.376,1.376,0,0,1,11.11,17.676ZM8.186,13.064a.312.312,0,0,0-.312.312v2.93a.312.312,0,0,0,.312.312H11.11a.312.312,0,0,0,.312-.312h0v-2.93a.312.312,0,0,0-.312-.312Z" transform="translate(-1.397 -0.614)"/>
            </g>
            </svg>
    );
}
export const ConfigIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.729" height="17.182" viewBox="0 0 16.729 17.182">
            <g id="settings" transform="translate(-2.715 -2.295)">
                <path fill={ `${color}`} id="Trazado_230" data-name="Trazado 230" d="M17.845,11.353v-.941l1.182-1.034a1.231,1.231,0,0,0,.234-1.569L17.808,5.347a1.241,1.241,0,0,0-1.458-.554l-1.5.5a6.985,6.985,0,0,0-.806-.462l-.314-1.551A1.231,1.231,0,0,0,12.5,2.295H9.623a1.231,1.231,0,0,0-1.231.991L8.078,4.836a7.064,7.064,0,0,0-.812.462L5.8,4.768a1.231,1.231,0,0,0-.393-.037,1.231,1.231,0,0,0-1.064.615L2.892,7.809a1.231,1.231,0,0,0,.252,1.545l1.163,1.064v.941L3.144,12.393a1.231,1.231,0,0,0-.252,1.569l1.452,2.462a1.241,1.241,0,0,0,1.458.554l1.5-.5a6.985,6.985,0,0,0,.806.462l.314,1.551a1.231,1.231,0,0,0,1.231.991h2.9a1.231,1.231,0,0,0,1.231-.991l.314-1.551a7.065,7.065,0,0,0,.812-.462l1.489.5a1.241,1.241,0,0,0,1.458-.554l1.4-2.462a1.231,1.231,0,0,0-.252-1.545Zm-1.1,4.455-2.111-.714a5.452,5.452,0,0,1-1.668.966l-.437,2.209h-2.9l-.437-2.185a5.76,5.76,0,0,1-1.662-.966l-2.116.689L3.956,13.347,5.63,11.87a5.477,5.477,0,0,1,0-1.926L3.956,8.424,5.408,5.962l2.111.714A5.452,5.452,0,0,1,9.187,5.71L9.624,3.5h2.9l.437,2.185a5.76,5.76,0,0,1,1.662.966l2.116-.689L18.2,8.424,16.522,9.9a5.477,5.477,0,0,1,0,1.926l1.674,1.52Z"/>
                <path fill={ `${color}`} id="Trazado_231" data-name="Trazado 231" d="M14.942,18.635a3.656,3.656,0,1,1,2.622-1.071A3.692,3.692,0,0,1,14.942,18.635Zm0-6.154a2.406,2.406,0,1,0,1.757.7,2.406,2.406,0,0,0-1.757-.7Z" transform="translate(-3.866 -4.057)"/>
            </g>
        </svg>

    );
}
export const ClientesIcon = ({color, width="19.75", height="17"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 16.663">
            <path fill={ `${color}`} id="Trazado_234" data-name="Trazado 234" d="M118.461,126.312h-6.217a3.087,3.087,0,0,0-3.05-2.644h-.336a3.087,3.087,0,0,0-3.05,2.644H99.593a.44.44,0,0,0-.441.44h0V139.89a.44.44,0,0,0,.44.441h18.869a.44.44,0,0,0,.441-.44h0V126.753a.44.44,0,0,0-.44-.441Zm-8.238,4.911h-2.392a.441.441,0,0,0-.441.441v1.456h-4.769a1.442,1.442,0,0,1-1.416-1.136l-1.063-4.79h17.77l-1.063,4.79a1.442,1.442,0,0,1-1.416,1.136h-4.769v-1.456a.44.44,0,0,0-.44-.441Zm-1.952,2.338V132.1h1.511v3.148h-1.511Zm.588-9.012h.336a2.208,2.208,0,0,1,2.159,1.763H106.7a2.207,2.207,0,0,1,2.159-1.762Zm-8.825,14.9v-8.677l.311,1.4A2.317,2.317,0,0,0,102.621,134h4.769v1.692a.441.441,0,0,0,.441.441h2.392a.441.441,0,0,0,.441-.441V134h4.769a2.317,2.317,0,0,0,2.276-1.826l.312-1.4v8.678Z" transform="translate(-99.152 -123.668)"/>
        </svg>

    );
}
export const PuntoVentaIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.018" height="17.134" viewBox="0 0 15.018 17.134">
        <g id="handbag" transform="translate(-2.222)">
            <path fill={ `${color}`} id="Trazado_419" data-name="Trazado 419" d="M14.463,1.071a2.142,2.142,0,0,0-2.142,2.142V8.031a.535.535,0,1,1-1.071,0V3.213a3.213,3.213,0,1,1,6.425,0V8.031a.535.535,0,0,1-1.071,0V3.213A2.142,2.142,0,0,0,14.463,1.071Z" transform="translate(-4.731 0)" fillRule="evenodd"/>
            <path fill={ `${color}`} id="Trazado_420" data-name="Trazado 420" d="M4.811,12.321a.535.535,0,0,0-.534.482L3.3,20.191a1.606,1.606,0,0,0,1.6,1.767h9.663a1.606,1.606,0,0,0,1.6-1.767L15.184,12.8a.535.535,0,0,0-.532-.482H4.811Zm-1.6.375a1.606,1.606,0,0,1,1.6-1.445h9.841a1.606,1.606,0,0,1,1.6,1.446l.977,7.391a2.677,2.677,0,0,1-2.664,2.943H4.9a2.677,2.677,0,0,1-2.664-2.944L3.212,12.7Z" transform="translate(0 -5.896)" fillRule="evenodd"/>
        </g>
        </svg>
    );
}
export const PreciosEspecialesIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.75" height="18.751" viewBox="0 0 18.75 20">
            <g id="Grupo_783" data-name="Grupo 783" transform="translate(-0.026 -0.026)">
                <path fill={ `${color}`} id="Trazado_248" data-name="Trazado 248" d="M12.406,18.777a2.118,2.118,0,0,1-.878-.19l-1.572-.715a1.342,1.342,0,0,0-1.109,0l-1.572.714A2.122,2.122,0,0,1,4.41,17.4L3.8,15.783A1.338,1.338,0,0,0,3.02,15L1.4,14.393A2.122,2.122,0,0,1,.216,11.527L.931,9.955a1.345,1.345,0,0,0,0-1.109L.215,7.274A2.122,2.122,0,0,1,1.4,4.409L3.019,3.8A1.338,1.338,0,0,0,3.8,3.019L4.409,1.4A2.122,2.122,0,0,1,7.274.215L8.846.93a1.342,1.342,0,0,0,1.109,0L11.527.216A2.122,2.122,0,0,1,14.393,1.4L15,3.02a1.338,1.338,0,0,0,.784.784L17.4,4.41a2.122,2.122,0,0,1,1.186,2.865l-.715,1.572a1.345,1.345,0,0,0,0,1.109l.714,1.572A2.122,2.122,0,0,1,17.4,14.393L15.782,15a1.338,1.338,0,0,0-.784.784L14.392,17.4a2.121,2.121,0,0,1-1.985,1.376ZM9.4,16.971a2.123,2.123,0,0,1,.877.19l1.573.715a1.341,1.341,0,0,0,1.81-.749l.606-1.617a2.119,2.119,0,0,1,1.241-1.241l1.617-.606a1.341,1.341,0,0,0,.749-1.81L17.16,10.28a2.124,2.124,0,0,1,0-1.756l.715-1.573a1.341,1.341,0,0,0-.749-1.81l-1.617-.606a2.119,2.119,0,0,1-1.241-1.241l-.606-1.617a1.341,1.341,0,0,0-1.81-.749l-1.572.715a2.128,2.128,0,0,1-1.755,0L6.951.927a1.341,1.341,0,0,0-1.81.749L4.535,3.293A2.122,2.122,0,0,1,3.294,4.535l-1.617.606a1.342,1.342,0,0,0-.75,1.81l.715,1.572a2.124,2.124,0,0,1,0,1.756L.927,11.852a1.341,1.341,0,0,0,.749,1.81l1.617.606a2.119,2.119,0,0,1,1.241,1.241l.606,1.617a1.341,1.341,0,0,0,1.81.749l1.572-.715a2.147,2.147,0,0,1,.879-.189Zm.717-15.685h0Z" transform="translate(0 0)"/>
                <path fill={ `${color}`} id="Trazado_249" data-name="Trazado 249" d="M129.953,110.574a1.953,1.953,0,1,1,1.953-1.953A1.956,1.956,0,0,1,129.953,110.574Zm0-3.125a1.172,1.172,0,1,0,1.172,1.172A1.173,1.173,0,0,0,129.953,107.448Z" transform="translate(-123.287 -102.735)" />
                <path fill={ `${color}`} id="Trazado_250" data-name="Trazado 250" d="M279.286,302.574a1.953,1.953,0,1,1,1.953-1.953A1.956,1.956,0,0,1,279.286,302.574Zm0-3.125a1.172,1.172,0,1,0,1.172,1.172A1.173,1.173,0,0,0,279.286,299.448Z" transform="translate(-267.15 -287.703)" />
                <path fill={ `${color}`} id="Trazado_251" data-name="Trazado 251" d="M171.064,137.4a.39.39,0,0,1-.329-.6l5.47-8.595a.39.39,0,0,1,.659.42l-5.47,8.6a.391.391,0,0,1-.33.18Z" transform="translate(-164.397 -123.308)" />
            </g>
        </svg>
    );
}
export const NotificacionesIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.583" height="15.19" viewBox="0 0 15 15.19">
            <g id="notification-outline-badged" transform="translate(-3.05 -1)">
                <path fill={ `${color}`} id="Trazado_157" data-name="Trazado 157" d="M16.543,33.041A1.219,1.219,0,0,0,17.721,32h-2.4A1.219,1.219,0,0,0,16.543,33.041Z" transform="translate(-6.67 -16.851)"/>
                <path fill={ `${color}`} id="Trazado_158" data-name="Trazado 158" d="M16.5,13.667A6.573,6.573,0,0,1,15.35,12.33a5.765,5.765,0,0,1-.616-2.2V7.88a4.984,4.984,0,0,0-.073-.817,3.4,3.4,0,0,1-1.022-.383,4.057,4.057,0,0,1,.183,1.2v2.255a6.5,6.5,0,0,0,.753,2.67,7.38,7.38,0,0,0,1.114,1.369H4a7.38,7.38,0,0,0,1.114-1.369,6.5,6.5,0,0,0,.753-2.67V7.88A4.016,4.016,0,0,1,9.873,3.845a3.93,3.93,0,0,1,2.173.657,3.419,3.419,0,0,1-.119-.8V3.416a4.829,4.829,0,0,0-1.515-.456V2.384a.609.609,0,1,0-1.219,0v.6a4.934,4.934,0,0,0-4.245,4.9v2.255a5.765,5.765,0,0,1-.616,2.2,6.572,6.572,0,0,1-1.127,1.337.456.456,0,0,0-.155.342v.621a.456.456,0,0,0,.456.456H16.195a.456.456,0,0,0,.456-.456v-.621A.456.456,0,0,0,16.5,13.667Z" transform="translate(0 -0.421)"/>
                <path fill={ `${color}`} id="Trazado_159" data-name="Trazado 159" d="M29.564,3.282A2.282,2.282,0,1,1,27.282,1a2.282,2.282,0,0,1,2.282,2.282Z" transform="translate(-11.932 0)"/>
            </g>
        </svg>
    );
}
export const SucursalesIcon = ({color, width="13.529", height="20.642"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 20.642">
            <g id="Grupo_782" data-name="Grupo 782" transform="translate(-84.424)">
                <path fill={ `${color}`} id="Trazado_232" data-name="Trazado 232" d="M91.189,0a6.77,6.77,0,0,0-6.765,6.76c0,3.582,6.148,13.276,6.409,13.687a.421.421,0,0,0,.711,0c.262-.411,6.409-10.1,6.409-13.687A6.77,6.77,0,0,0,91.189,0Zm0,19.426c-.556-.9-1.685-2.763-2.8-4.827-2.04-3.767-3.119-6.477-3.119-7.838a5.922,5.922,0,0,1,11.844,0c0,1.361-1.078,4.071-3.119,7.838C92.874,16.663,91.745,18.526,91.189,19.426Z" />
                <path fill={ `${color}`} id="Trazado_233" data-name="Trazado 233" d="M172.24,76.071a3.114,3.114,0,1,0,3.2,3.113A3.161,3.161,0,0,0,172.24,76.071Zm0,5.383a2.272,2.272,0,1,1,2.357-2.27A2.318,2.318,0,0,1,172.24,81.454Z" transform="translate(-81.051 -72.866)" />
            </g>
        </svg>
    );
}

export const SucursalesFilledIcon = ({color = "#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.431" height="23.241" viewBox="0 0 17.431 23.241">
            <g id="Grupo_1533" data-name="Grupo 1533" transform="translate(-85.769 -3.481)">
                <g id="geo-alt" transform="translate(81.269 3.481)">
                <path id="Trazado_810" data-name="Trazado 810" d="M13.215,23.241s8.715-8.259,8.715-14.526a8.715,8.715,0,0,0-17.431,0C4.5,14.982,13.215,23.241,13.215,23.241Zm0-10.168A4.358,4.358,0,1,0,8.858,8.715,4.358,4.358,0,0,0,13.215,13.073Z" transform="translate(0 0)" fill={ color } fillRule="evenodd"/>
                </g>
            </g>
        </svg>
    );
}
export const SuperAdminIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.582" height="16.883" viewBox="0 0 16.582 16.883">
            <g id="administrator-line" transform="translate(-2 -1.29)">
                <path fill={ `${color}`} id="Trazado_161" data-name="Trazado 161" d="M11.44,8.331a3.52,3.52,0,1,1,3.52-3.515A3.52,3.52,0,0,1,11.44,8.331Zm0-5.994a2.479,2.479,0,1,0,2.479,2.479A2.479,2.479,0,0,0,11.44,2.337Z" transform="translate(-2.837 0)"/>
                <path fill={ `${color}`} id="Trazado_162" data-name="Trazado 162" d="M9.509,24.892a1.114,1.114,0,0,1-.323-.875H3.042v-3.01A7.712,7.712,0,0,1,8.817,18.57h.375a1.146,1.146,0,0,1,.323-.963l.062-.057c-.245,0-.521-.031-.76-.031A8.577,8.577,0,0,0,2.1,20.507a.521.521,0,0,0-.1.312v3.2a1.042,1.042,0,0,0,1.042,1.042H9.655Z" transform="translate(0 -7.776)"/>
                <path fill={ `${color}`} id="Trazado_163" data-name="Trazado 163" d="M26.87,16.286a.193.193,0,0,1,.078,0,.219.219,0,0,0-.078,0Z" transform="translate(-11.919 -7.185)"/>
                <path fill={ `${color}`} id="Trazado_164" data-name="Trazado 164" d="M25.082,19.634l-1.042-.318a3.755,3.755,0,0,0-.3-.734l.521-.969a.2.2,0,0,0-.052-.229l-.755-.755a.187.187,0,0,0-.229-.036l-.958.521a3.723,3.723,0,0,0-.745-.318L21.2,15.755a.187.187,0,0,0-.187-.125H19.948a.187.187,0,0,0-.182.135l-.318,1.042a3.645,3.645,0,0,0-.75.312L17.75,16.6a.182.182,0,0,0-.224.036l-.771.75a.2.2,0,0,0-.031.229l.521.948a3.526,3.526,0,0,0-.328.745l-1.042.312a.187.187,0,0,0-.135.182v1.068a.182.182,0,0,0,.135.161l1.042.318a3.645,3.645,0,0,0,.312.734l-.521.995a.187.187,0,0,0,.031.224l.755.755a.2.2,0,0,0,.229.036l.974-.521a3.692,3.692,0,0,0,.729.3l.312,1.042a.2.2,0,0,0,.182.135h1.068a.193.193,0,0,0,.182-.135l.318-1.068a3.6,3.6,0,0,0,.719-.3l.984.521a.187.187,0,0,0,.224-.036l.792-.708a.182.182,0,0,0,0-.208l-.521-.979a3.645,3.645,0,0,0,.3-.724l1.042-.318a.187.187,0,0,0,.135-.182V19.817A.187.187,0,0,0,25.082,19.634Zm-4.6,2.437a1.739,1.739,0,1,1,1.734-1.734,1.739,1.739,0,0,1-1.734,1.734Z" transform="translate(-6.585 -6.872)"/>
            </g>
        </svg>
    );
}
export const UpdateImageIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.332" height="11.312" viewBox="0 0 11.332 11.312">
            <path id="icon" d="M10.2.032a1.132,1.132,0,1,0,1.017.631A1.131,1.131,0,0,0,10.2.032ZM5.677,1.163A4.525,4.525,0,0,0,2.045,8.386L3.266,7.165A2.828,2.828,0,0,1,7.154,3.276L8.375,2.055a4.5,4.5,0,0,0-2.7-.893ZM9.308,2.988,8.088,4.209A2.827,2.827,0,0,1,4.2,8.1L2.979,9.319a4.524,4.524,0,0,0,6.33-6.33ZM1.133,9.08a1.131,1.131,0,1,0,1.11.909,1.132,1.132,0,0,0-1.11-.909Z" transform="translate(-0.003 -0.032)"/>
        </svg>
    );
}

export const LinkImageIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.865" height="7.657" viewBox="0 0 17.865 7.657">
            <g id="link" transform="translate(0 0)">
                <path id="Trazado_833" data-name="Trazado 833" d="M9.082,12.375h-3a3.828,3.828,0,1,0,0,7.657H9.907a3.828,3.828,0,0,0,3.611-5.1H12.459a1.241,1.241,0,0,0-.319.04,2.552,2.552,0,0,1-2.233,3.789H6.078a2.552,2.552,0,1,1,0-5.1H8.037a5.114,5.114,0,0,1,1.046-1.276Z" transform="translate(-2.25 -12.375)" fill="#524f4f"/>
                <path id="Trazado_834" data-name="Trazado 834" d="M17.327,12.375a3.828,3.828,0,0,0-3.611,5.1h1.4a2.552,2.552,0,0,1,2.21-3.828h3.828a2.552,2.552,0,1,1,0,5.1H19.2a5.13,5.13,0,0,1-1.046,1.276h3.005a3.828,3.828,0,1,0,0-7.657Z" transform="translate(-7.118 -12.375)" fill="#524f4f"/>
            </g>
        </svg>
    );
}

export const TitleImageIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.636" height="9.942" viewBox="0 0 10.636 9.942">
            <path id="text" d="M15.7,8.5V6.188H5.063V8.5h1.85V7.113H9.456v8.093H7.838v.925h5.087v-.925H11.306V7.113h2.544V8.5Z" transform="translate(-5.063 -6.188)"/>
        </svg>
    );
}

export const DescriptionImageIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
            <g id="text-align-justify" transform="translate(-6.75 -6.75)">
                <path id="Trazado_835" data-name="Trazado 835" d="M6.75,6.75h10v1h-10Z"/>
                <path id="Trazado_836" data-name="Trazado 836" d="M6.75,13.5h10v1h-10Z" transform="translate(0 -3.75)"/>
                <path id="Trazado_837" data-name="Trazado 837" d="M6.75,20.25h10v1h-10Z" transform="translate(0 -7.5)"/>
                <path id="Trazado_838" data-name="Trazado 838" d="M6.75,27h10v1h-10Z" transform="translate(0 -11.25)"/>
            </g>
        </svg>
    );
}

export const UploadImageIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.529" height="9.95" viewBox="0 0 8.529 9.95">
            <path id="file" d="M5.686,2.843V.222a1.162,1.162,0,0,1,.2.156L8.151,2.643a1.18,1.18,0,0,1,.156.2H5.686Zm-.711.178a.531.531,0,0,0,.533.533H8.529V9.417A.531.531,0,0,1,8,9.95H.533a.513.513,0,0,1-.378-.156A.516.516,0,0,1,0,9.417V.533A.513.513,0,0,1,.156.156.516.516,0,0,1,.533,0H4.975Z" fill={color}/>
        </svg>

    );
}

export const TrashIcon = ({color = "#393d40", width="20.408", height="20.239"}) => {
    return (
        <svg id="Grupo_456" data-name="Grupo 456" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20.408 20.239">
            <path id="Trazado_14" data-name="Trazado 14" d="M39.365,38.9a.567.567,0,0,0-.565.565v7.66a.565.565,0,1,0,1.131,0v-7.66A.567.567,0,0,0,39.365,38.9Z" transform="translate(-31.479 -31.635)" fill={ color }/>
        <path id="Trazado_15" data-name="Trazado 15" d="M55.765,38.9a.567.567,0,0,0-.565.565v7.66a.565.565,0,0,0,1.131,0v-7.66A.567.567,0,0,0,55.765,38.9Z" transform="translate(-43.243 -31.635)" fill={ color }/>
            <path id="Trazado_16" data-name="Trazado 16" d="M25.592,13.2H20.617a1.966,1.966,0,0,0-1.979,1.979h0v1.1H13.465a.565.565,0,0,0,0,1.131h1.781l.9,14.162a1.982,1.982,0,0,0,1.979,1.866h9.95a1.982,1.982,0,0,0,1.979-1.866l.9-14.162h1.781a.565.565,0,0,0,0-1.131H27.57v-1.1A1.966,1.966,0,0,0,25.592,13.2Zm-5.823,1.979a.833.833,0,0,1,.848-.848h4.975a.833.833,0,0,1,.848.848v1.1H19.769Zm9.158,16.338a.84.84,0,0,1-.848.791h-9.95a.84.84,0,0,1-.848-.791l-.9-14.105H29.832Z" transform="translate(-12.9 -13.2)" fill={ color }/>
        </svg>
    );
}

export const ChevronDownIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.841" height="5.097" viewBox="0 0 8.841 5.097">
            <path id="Trazado_827" data-name="Trazado 827" d="M4417,557.766l4.033,4.186L4417,565.9" transform="translate(566.26 -4416.64) rotate(90)" fill="none" stroke={ color } strokeWidth="1"/>
        </svg>
    );
}

export const TelephoneIcon = ({color = "#393d40", width="17.76", height="17.758"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17.76 17.758">
            <g id="telephone" transform="translate(-1.124 -1.128)">
                <path id="Trazado_830" data-name="Trazado 830" d="M5.179,2.6a.753.753,0,0,0-1.126-.07L2.9,3.679a1.9,1.9,0,0,0-.5,1.963,19.5,19.5,0,0,0,4.626,7.336A19.5,19.5,0,0,0,14.368,17.6a1.9,1.9,0,0,0,1.963-.5l1.149-1.149a.753.753,0,0,0-.071-1.126l-2.559-1.991A.753.753,0,0,0,14.2,12.7l-2.429.607a1.937,1.937,0,0,1-1.84-.509L7.21,10.074A1.937,1.937,0,0,1,6.7,8.234L7.308,5.8a.753.753,0,0,0-.136-.644ZM3.217,1.694a1.937,1.937,0,0,1,2.9.181L8.1,4.435a1.932,1.932,0,0,1,.35,1.657l-.607,2.43a.753.753,0,0,0,.2.715l2.728,2.726a.753.753,0,0,0,.715.2l2.429-.607a1.937,1.937,0,0,1,1.659.35l2.559,1.99a1.936,1.936,0,0,1,.181,2.9l-1.149,1.149a3.085,3.085,0,0,1-3.193.779A20.682,20.682,0,0,1,6.2,13.814a20.682,20.682,0,0,1-4.907-7.78,3.086,3.086,0,0,1,.779-3.194L3.217,1.693Z" transform="translate(0 0)" fill={`${color}`} fillRule="evenodd"/>
            </g>
        </svg>

    );
}

export const MailIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.775" viewBox="0 0 18 17.775">
            <g id="envelope-open" transform="translate(0 -0.45)">
                <path id="Trazado_831" data-name="Trazado 831" d="M9.529,1.708a1.125,1.125,0,0,0-1.057,0l-6.75,3.6a1.125,1.125,0,0,0-.6.992v.92l6.439,3.9L9,10.256l1.436.862,6.439-3.9V6.3a1.125,1.125,0,0,0-.6-.992l-6.75-3.6Zm7.346,6.828-5.348,3.236,5.348,3.209V8.535Zm-.039,7.733L9,11.568l-7.835,4.7A1.125,1.125,0,0,0,2.25,17.1h13.5a1.125,1.125,0,0,0,1.085-.83ZM1.125,14.981l5.348-3.208L1.125,8.535v6.446ZM7.942.714a2.25,2.25,0,0,1,2.117,0l6.75,3.6A2.25,2.25,0,0,1,18,6.3v9.675a2.25,2.25,0,0,1-2.25,2.25H2.25A2.25,2.25,0,0,1,0,15.975V6.3A2.25,2.25,0,0,1,1.192,4.314l6.75-3.6Z" fill={`${color}`} fillRule="evenodd"/>
            </g>
        </svg>
    );
}

export const DownloadIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9.566" height="10.942" viewBox="0 0 9.566 10.942">
            <path id="upload" d="M0,1.969H9.566V3.345H0ZM0,8.816l4.767-4.8,4.8,4.8H6.815v4.1h-4.1v-4.1H0Z" transform="translate(0 -1.969)" fill={`${color}`}/>
        </svg>
    );
}

export const MessagesIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17.857" viewBox="0 0 25 17.857">
            <path id="email" d="M25.464,6.75H4.036A1.786,1.786,0,0,0,2.25,8.536V22.821a1.786,1.786,0,0,0,1.786,1.786H25.464a1.786,1.786,0,0,0,1.786-1.786V8.536A1.786,1.786,0,0,0,25.464,6.75ZM23.5,8.536l-8.75,6.053L6,8.536ZM4.036,22.821V9.348l10.206,7.063a.893.893,0,0,0,1.017,0L25.464,9.348V22.821Z" transform="translate(-2.25 -6.75)" fill={`${color}`}/>
        </svg>
    );
}

export const ContactIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20.705" viewBox="0 0 25 20.705">
            <g id="license-line" transform="translate(-2 -6)">
                <path fill={`${color}`} id="Trazado_876" data-name="Trazado 876" d="M25.438,6H3.563A1.517,1.517,0,0,0,2,7.468V22.147a1.517,1.517,0,0,0,1.562,1.468H15.281l.445-.514.727-.837-.07-.117H3.563V7.468H25.438V13.75A6.446,6.446,0,0,1,27,15.079V7.468A1.517,1.517,0,0,0,25.438,6Z" transform="translate(0)"/>
                <path fill={`${color}`} id="Trazado_877" data-name="Trazado 877" d="M7,12H19.477v1.174H7Z" transform="translate(-0.826 -1.596)"/>
                <path fill={`${color}`} id="Trazado_878" data-name="Trazado 878" d="M7,16h8.073v1.174H7Z" transform="translate(-0.97 -2.661)"/>
                <path fill={`${color}`} id="Trazado_879" data-name="Trazado 879" d="M7,23h7.339v1.174H7Z" transform="translate(-0.986 -4.523)"/>
                <path fill={`${color}`} id="Trazado_880" data-name="Trazado 880" d="M25.754,17.23A4.668,4.668,0,0,0,22.525,25.3L21.1,27.043l.661,2.65,2.686-3.273a4.594,4.594,0,0,0,2.606,0l2.686,3.273.661-2.65L28.976,25.3a4.668,4.668,0,0,0-3.229-8.073Zm0,7.839a3.163,3.163,0,1,1,3.207-3.163A3.163,3.163,0,0,1,25.754,25.069Z" transform="translate(-3.554 -2.988)"/>
            </g>
        </svg>
    );
}

export const SectorIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.998" height="25" viewBox="0 0 24.998 25">
            <g id="tag" transform="translate(27.248 -2.25) rotate(90)">
                <path id="Trazado_861" data-name="Trazado 861" d="M17,27.248a2.678,2.678,0,0,1-1.893-.786L3.036,14.4a2.678,2.678,0,0,1-.786-1.893V4.928A2.678,2.678,0,0,1,4.928,2.25h7.58a2.678,2.678,0,0,1,1.893.786L26.462,15.1a2.678,2.678,0,0,1,0,3.794l-7.571,7.571A2.678,2.678,0,0,1,17,27.248ZM4.928,4.036a.893.893,0,0,0-.893.893v7.58a.893.893,0,0,0,.267.634L16.365,25.2a.893.893,0,0,0,1.259,0l7.58-7.58a.893.893,0,0,0,0-1.259L13.142,4.3a.893.893,0,0,0-.634-.267Z"  fill={`${color}`}/>
                <path id="Trazado_862" data-name="Trazado 862" d="M9.346,11.942a2.6,2.6,0,1,1,2.6-2.6A2.6,2.6,0,0,1,9.346,11.942Zm0-3.894a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,9.346,8.048Z" transform="translate(-1.904 -1.904)"  fill={`${color}`}/>
            </g>
        </svg>
    );
}

export const EmpresasIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.583" height="20.041" viewBox="0 0 21.583 20.041">
            <path fill={`${color}`} id="directory-line" d="M24.041,8.083H13.573L11.793,5.632A1.542,1.542,0,0,0,10.544,5h-5A1.542,1.542,0,0,0,4,6.542V23.5a1.542,1.542,0,0,0,1.542,1.542h18.5A1.542,1.542,0,0,0,25.583,23.5V9.625A1.542,1.542,0,0,0,24.041,8.083Zm0,15.416H5.542V11.166h5.635a1.542,1.542,0,0,0,1.542-1.542H5.542V6.542h5l2.012,2.767a.771.771,0,0,0,.624.316H24.041Z" transform="translate(-4 -5)"/>
        </svg>
    );
}

export const IdiomasIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.125" height="24.707" viewBox="0 0 25.125 24.707">
            <g id="language" transform="translate(-1.125 -1.688)">
                <path fill={`${color}`} id="Trazado_796" data-name="Trazado 796" d="M17.038,1.688H1.125V17.6h9.213v8.794H26.25V10.481H17.038ZM8.051,5.456l-2.629,7.5H7.2l.427-1.219h2.714v4.188H2.8V3.363H15.363v7.119h-3.49l-1.76-5.026Zm1.9,4.607H8.212l.87-2.483Zm14.623,2.093V24.719H12.012V12.156Z"/>
                <path fill={`${color}`} id="Trazado_797" data-name="Trazado 797" d="M21.646,24.031a7.159,7.159,0,0,0,.528.739,4.292,4.292,0,0,1-2.069.492v1.675a5.856,5.856,0,0,0,3.35-.986,5.86,5.86,0,0,0,3.351.986V25.263a4.292,4.292,0,0,1-2.069-.492,7.148,7.148,0,0,0,.528-.738,8.7,8.7,0,0,0,1.052-2.539h.908V19.819H24.294V18.562H22.619v1.257H19.687v1.675h.907a8.7,8.7,0,0,0,1.052,2.538Zm2.937-2.538a6.962,6.962,0,0,1-.738,1.65,5.464,5.464,0,0,1-.389.547,5.421,5.421,0,0,1-.363-.505,6.929,6.929,0,0,1-.765-1.691Z" transform="translate(-4.744 -4.312)"/>
            </g>
        </svg>
    );
}

export const PlanesIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.32" height="23.32" viewBox="0 0 23.32 23.32">
            <path fill={`${color}`} id="parent-child" d="M23.9,10.579A1.666,1.666,0,0,0,25.57,8.913v-5A1.666,1.666,0,0,0,23.9,2.25H3.916A1.666,1.666,0,0,0,2.25,3.916v5a1.666,1.666,0,0,0,1.666,1.666h9.162V13.91h-5a1.666,1.666,0,0,0-1.666,1.666v3.331h-2.5A1.666,1.666,0,0,0,2.25,20.573V23.9A1.666,1.666,0,0,0,3.916,25.57h6.663A1.666,1.666,0,0,0,12.244,23.9V20.573a1.666,1.666,0,0,0-1.666-1.666H8.08V15.576H19.74v3.331h-2.5a1.666,1.666,0,0,0-1.666,1.666V23.9a1.666,1.666,0,0,0,1.666,1.666H23.9A1.666,1.666,0,0,0,25.57,23.9V20.573A1.666,1.666,0,0,0,23.9,18.907h-2.5V15.576A1.666,1.666,0,0,0,19.74,13.91h-5V10.579ZM10.579,23.9H3.916V20.573h6.663Zm13.326,0H17.242V20.573H23.9ZM3.916,3.916H23.9v5H3.916Z" transform="translate(-2.25 -2.25)"/>
        </svg>
    );
}

export const ArrowBackIcon = ({color = "#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.127" viewBox="0 0 15 13.127">
            <g id="arrow-left-short" transform="translate(-8.998 -10.123)">
                <path fill={`${color}`} id="Trazado_248" data-name="Trazado 248" d="M17.672,10.4a.832.832,0,0,1,0,1.327l-5.956,4.96,5.956,4.961a.833.833,0,0,1,0,1.327,1.291,1.291,0,0,1-1.593,0l-6.75-5.625a.832.832,0,0,1,0-1.327l6.75-5.625a1.289,1.289,0,0,1,1.593,0Z" fillRule="evenodd"/>
                <path fill={`${color}`} id="Trazado_249" data-name="Trazado 249" d="M10.125,18a1.04,1.04,0,0,1,.925-1.125H23.074A1.04,1.04,0,0,1,24,18a1.04,1.04,0,0,1-.925,1.125H11.05A1.04,1.04,0,0,1,10.125,18Z" transform="translate(0 -1.313)" fillRule="evenodd"/>
            </g>
        </svg>
    );
}

export const AvatarIcon = () => {
    return (
        <svg id="Grupo_592" data-name="Grupo 592" xmlns="http://www.w3.org/2000/svg" width="57.691" height="57.691" viewBox="0 0 57.691 57.691">
            <path id="Trazado_377" data-name="Trazado 377" d="M113.675,0,95.6,28.846l18.073,28.846a28.846,28.846,0,1,0,0-57.691Z" transform="translate(-84.83)" fill="#f4f8fe"/>
            <path id="Trazado_378" data-name="Trazado 378" d="M0,28.846A28.846,28.846,0,0,0,28.846,57.691V0A28.846,28.846,0,0,0,0,28.846Z" fill="#fff"/>
            <path id="Trazado_379" data-name="Trazado 379" d="M197.625,60l-7.413,11.831,7.413,11.831a11.831,11.831,0,1,0,0-23.662Z" transform="translate(-168.779 -53.239)" fill="#4189e8"/>
            <path id="Trazado_380" data-name="Trazado 380" d="M151,71.831a11.831,11.831,0,0,0,11.831,11.831V60A11.831,11.831,0,0,0,151,71.831Z" transform="translate(-133.986 -53.239)" fill="#4595ff"/>
            <path id="Trazado_381" data-name="Trazado 381" d="M221.764,303.994A11.841,11.841,0,0,0,212.9,300H202.761L196,308.564l6.761,8.564a22.145,22.145,0,0,0,19.207-11.177l.6-1.051Z" transform="translate(-173.915 -266.196)" fill="#4189e8"/>
            <path id="Trazado_382" data-name="Trazado 382" d="M89.9,300a11.842,11.842,0,0,0-8.862,3.994l-.8.905.6,1.051a22.145,22.145,0,0,0,19.206,11.177V300Z" transform="translate(-71.2 -266.196)" fill="#4595ff"/>
        </svg>
    );
}

export const DelayIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.9" height="17.915" viewBox="0 0 20.9 17.915">
            <path fill={`${color}`} id="time-restore" d="M11.943,0a8.638,8.638,0,0,1,6.345,2.613A8.638,8.638,0,0,1,20.9,8.958,8.638,8.638,0,0,1,18.288,15.3a8.638,8.638,0,0,1-6.345,2.613A8.74,8.74,0,0,1,5.6,15.3l1.446-1.446a6.588,6.588,0,0,0,4.9,2.053,6.7,6.7,0,0,0,4.922-2.03,6.705,6.705,0,0,0,2.03-4.922,6.7,6.7,0,0,0-2.03-4.922,6.7,6.7,0,0,0-4.922-2.03,6.7,6.7,0,0,0-4.921,2.03,6.7,6.7,0,0,0-2.03,4.922H7.978L3.919,12.97l-.046-.14L0,8.958H2.986A8.638,8.638,0,0,1,5.6,2.613,8.638,8.638,0,0,1,11.943,0Zm-.98,4.992h1.493v4.2l3.452,2.1-.7,1.213L10.962,9.936V4.992Z"/>
        </svg>

    );
}

export const HorarioIcon = ({color}) => {
    return (
        <svg id="time" xmlns="http://www.w3.org/2000/svg" width="14.12" height="14.12" viewBox="0 0 14.12 14.12">
            <path fill={`${color}`} id="Trazado_287" data-name="Trazado 287" d="M9.31,16.37a7.06,7.06,0,1,1,7.06-7.06A7.06,7.06,0,0,1,9.31,16.37Zm0-13.112A6.051,6.051,0,1,0,15.362,9.31,6.051,6.051,0,0,0,9.31,3.259Z" transform="translate(-2.25 -2.25)"/>
            <path fill={`${color}`} id="Trazado_288" data-name="Trazado 288" d="M19.694,15.439,16.875,12.62V7.875h1.009V12.2l2.521,2.526Z" transform="translate(-10.319 -5.354)"/>
        </svg>
    );
}

export const SaveIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.515" height="18.515" viewBox="0 0 18.515 18.515">
            <path fill={`${color}`} id="save" d="M22.792,8.581,18.934,4.724a.771.771,0,0,0-.548-.224H6.043A1.543,1.543,0,0,0,4.5,6.043V21.472a1.543,1.543,0,0,0,1.543,1.543H21.472a1.543,1.543,0,0,0,1.543-1.543V9.129A.771.771,0,0,0,22.792,8.581ZM10.672,6.043h6.172V9.129H10.672Zm6.172,15.429H10.672V15.3h6.172Zm1.543,0V15.3a1.543,1.543,0,0,0-1.543-1.543H10.672A1.543,1.543,0,0,0,9.129,15.3v6.172H6.043V6.043H9.129V9.129a1.543,1.543,0,0,0,1.543,1.543h6.172a1.543,1.543,0,0,0,1.543-1.543V6.359l3.086,3.086V21.472Z" transform="translate(-4.5 -4.5)"/>
        </svg>
    );
}

export const ArrowDownSmallIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6">
            <g fill={`${color}`} id="Polígono_45" data-name="Polígono 45" transform="translate(9 6) rotate(180)">
                <path d="M 8 5.5 L 1 5.5 L 4.5 0.8333333134651184 L 8 5.5 Z" stroke="none"/>
                <path d="M 4.5 1.666666507720947 L 2 5 L 7 5 L 4.5 1.666666507720947 M 4.5 0 L 9 6 L 0 6 L 4.5 0 Z" stroke="none" fill={`${color}`}/>
            </g>
        </svg>
    );
}

export const UltimaReservaIcon = ({color}) => {
    return (
        <svg id="calendar-check" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path fill={`${color}`} id="Trazado_721" data-name="Trazado 721" d="M17.728,15.894a.5.5,0,0,1,0,.708l-3,3a.5.5,0,0,1-.708,0l-1.5-1.5a.5.5,0,1,1,.708-.708l1.146,1.147,2.646-2.647a.5.5,0,0,1,.708,0Z" transform="translate(-6.874 -8.749)" fillRule="evenodd"/>
            <path fill={`${color}`} id="Trazado_722" data-name="Trazado 722" d="M1,5.25v10a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1v-10Zm1-3a2,2,0,0,0-2,2v11a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2v-11a2,2,0,0,0-2-2Z" transform="translate(0 -1.25)" fillRule="evenodd"/>
            <path fill={`${color}`} id="Trazado_723" data-name="Trazado 723" d="M7.25,0a.5.5,0,0,1,.5.5V1a.5.5,0,1,1-1,0V.5A.5.5,0,0,1,7.25,0Zm9,0a.5.5,0,0,1,.5.5V1a.5.5,0,1,1-1,0V.5A.5.5,0,0,1,16.25,0Z" transform="translate(-3.75)" fillRule="evenodd"/>
        </svg>
    );
}

export const EnviarIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.449" height="14.429" viewBox="0 0 14.449 14.429">
            <path fill={`${color}`} id="send" d="M18.583,11.183,5.354,4.569a.6.6,0,0,0-.848.673l1.78,6.476-1.8,6.458a.612.612,0,0,0,.872.692l13.229-6.615a.6.6,0,0,0,0-1.07ZM6.015,17.2,7.344,12.32H12.9v-1.2H7.344L6.015,6.24l10.95,5.478Z" transform="translate(-4.461 -4.505)"/>
        </svg>
    );
}

export const ProfesionalAvatar = ({color}) => {
    return (
        <svg id="Grupo_602" data-name="Grupo 602" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.624 26.624">
            <path id="Trazado_377" data-name="Trazado 377" d="M103.943,0,95.6,13.312l8.341,13.312a13.312,13.312,0,1,0,0-26.624Z" transform="translate(-90.631)" fill="#f2f2f2"/>
            <path id="Trazado_378" data-name="Trazado 378" d="M0,13.312A13.312,13.312,0,0,0,13.312,26.624V0A13.312,13.312,0,0,0,0,13.312Z" fill="#fff"/>
            <path id="Trazado_379" data-name="Trazado 379" d="M192.7,60l-2.491,3.976,2.491,3.976a3.976,3.976,0,1,0,0-7.953Z" transform="translate(-179.391 -54.11)" fill="#e6e4e4"/>
            <path id="Trazado_380" data-name="Trazado 380" d="M151,63.976a3.976,3.976,0,0,0,3.976,3.976V60A3.976,3.976,0,0,0,151,63.976Z" transform="translate(-141.664 -54.11)" fill="#f4efef"/>
            <path id="Trazado_381" data-name="Trazado 381" d="M204.659,301.342A3.98,3.98,0,0,0,201.681,300h-3.408L196,302.878l2.272,2.878A7.443,7.443,0,0,0,204.727,302l.2-.353Z" transform="translate(-184.96 -285.022)" fill="#e6e4e4"/>
            <path id="Trazado_382" data-name="Trazado 382" d="M83.489,300a3.98,3.98,0,0,0-2.978,1.342l-.269.3.2.353a7.443,7.443,0,0,0,6.455,3.757V300Z" transform="translate(-73.585 -285.022)" fill="#f4efef"/>
        </svg>
    );
}

export const EditarIcon = ({color}) => {
    return (
        <svg id="__TEMP__SVG__" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17.08 15.86">
            <path fill={`${color}`} id="Trazado_195" data-name="Trazado 195" d="M7.077,92h17.08v1.22H7.077Z" transform="translate(-7.077 -77.36)"/>
            <path fill={`${color}`} id="Trazado_196" data-name="Trazado 196" d="M27.208,11.347a1.179,1.179,0,0,0,0-1.708l-2.2-2.2a1.179,1.179,0,0,0-1.708,0l-9.15,9.15v3.9h3.9ZM24.158,8.3l2.2,2.2-1.83,1.83-2.2-2.2Zm-8.784,10.98v-2.2l6.1-6.1,2.2,2.2-6.1,6.1Z" transform="translate(-12.934 -7.077)"/>
        </svg>
    );
}

export const AccordionCloseIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 20">
            <g id="Grupo_1592" data-name="Grupo 1592" transform="translate(-218 -449)">
                <circle id="Elipse_479" data-name="Elipse 479" cx="10" cy="10" r="10" transform="translate(218 449)" fill="#fff"/>
                <g id="Grupo_1591" data-name="Grupo 1591">
                <line id="Línea_455" data-name="Línea 455" x2="7" y2="7" transform="translate(224.5 455.5)" fill="none" stroke={ color } strokeLinecap="round" strokeWidth="1.5"/>
                <line id="Línea_456" data-name="Línea 456" x1="7" y2="7" transform="translate(224.5 455.5)" fill="none" stroke={ color } strokeLinecap="round" strokeWidth="1.5"/>
                </g>
            </g>
        </svg>

    );
}

export const AddSucursalIcon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Grupo_1593" data-name="Grupo 1593" transform="translate(-218 -449)">
                <circle id="Elipse_479" data-name="Elipse 479" cx="10" cy="10" r="10" transform="translate(218 449)" fill={`${color}`}/>
                <g id="Grupo_1591" data-name="Grupo 1591" transform="translate(391.342 -26.782) rotate(45)">
                <line id="Línea_455" data-name="Línea 455" x2="7" y2="7" transform="translate(224.5 455.5)" fill="none" stroke="#eff5fd" strokeLinecap="round" strokeWidth="1.5"/>
                <line id="Línea_456" data-name="Línea 456" x1="7" y2="7" transform="translate(224.5 455.5)" fill="none" stroke="#eff5fd" strokeLinecap="round" strokeWidth="1.5"/>
                </g>
            </g>
        </svg>
    );
}

export const ServiceTimeIcon = ({color = "#ffffff"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8.886" height="8.886" viewBox="0 0 8.886 8.886">
            <g id="clock" transform="translate(0.5 0.5)">
                <g id="timer-outline">
                <path id="Trazado_254" data-name="Trazado 254" d="M5.5,5.814A3.942,3.942,0,1,0,8.535,4.5a.09.09,0,0,0-.092.09V6.307" transform="translate(-4.499 -4.5)" fill="none" stroke={ color } strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                <path id="Trazado_255" data-name="Trazado 255" d="M12.907,14.115,10.792,11.09a.218.218,0,0,1,.3-.3L14.12,12.9a.87.87,0,1,1-1,1.426.889.889,0,0,1-.216-.212Z" transform="translate(-9.514 -9.509)" fill={ color }/>
                </g>
            </g>
        </svg>
    );
}

export const ServicePauseIcon = ({color = "#ffffff"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9.288" height="10.622" viewBox="0 0 9.288 10.622">
            <path id="timer-off" d="M8.339,2.093l.678.678-.678.7A4.161,4.161,0,0,1,9.288,6.16a4.227,4.227,0,0,1-.723,2.4l-.7-.7A3.361,3.361,0,0,0,8.316,6.16a3.246,3.246,0,0,0-.983-2.384,3.248,3.248,0,0,0-2.384-.983,3.361,3.361,0,0,0-1.695.452l-.7-.7a4.227,4.227,0,0,1,2.4-.723,4.251,4.251,0,0,1,2.712.949ZM6.4.375v.972H3.5V.375ZM4.452,4.443V3.765h.972v1.65ZM.61,1.821,5.446,6.658l3.706,3.729-.61.61-1.2-1.22a4.227,4.227,0,0,1-2.4.723A4.184,4.184,0,0,1,1.876,9.234,4.184,4.184,0,0,1,.61,6.16a4.227,4.227,0,0,1,.723-2.4L0,2.432ZM4.949,9.55a3.238,3.238,0,0,0,1.695-.475l-4.61-4.61a3.335,3.335,0,0,0,.52,4.09A3.265,3.265,0,0,0,4.949,9.55Z" transform="translate(0 -0.375)" fill={ color }/>
        </svg>
    );
}

export const ServicePriceIcon = ({color = "#ffffff"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9.789" height="9.789" viewBox="0 0 9.789 9.789">
            <path id="euro-solid" d="M6.895,2a4.895,4.895,0,1,0,4.895,4.895A4.895,4.895,0,0,0,6.895,2Zm2.27,7.7A3.328,3.328,0,0,1,4.212,7.812H3.175a.306.306,0,1,1,0-.612h.918c0-.107-.015-.217-.015-.327V6.589H3.159a.306.306,0,1,1,0-.612H4.2a3.322,3.322,0,0,1,4.953-1.93.382.382,0,0,1-.4.649A2.557,2.557,0,0,0,5,5.977H7.867a.306.306,0,1,1,0,.612H4.854a2.362,2.362,0,0,0,0,.612h3a.306.306,0,0,1,0,.612H5.019A2.557,2.557,0,0,0,8.758,9.036a.383.383,0,1,1,.407.649Z" transform="translate(-2 -2)" fill={ color }/>
        </svg>

    );
}

export const EyeIcon = ({color = "#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.009" height="11.006" viewBox="0 0 16.009 11.006">
            <g id="Grupo_1624" data-name="Grupo 1624" transform="translate(4.767 -7.563)">
                <g id="eye" transform="translate(-4.767 7.563)">
                    <path id="Trazado_258" data-name="Trazado 258" d="M16.009,11.128s-3-5.5-8-5.5-8,5.5-8,5.5,3,5.5,8,5.5S16.009,11.128,16.009,11.128Zm-14.836,0a13.142,13.142,0,0,0,1.661,2.044A7.456,7.456,0,0,0,8,15.631a7.462,7.462,0,0,0,5.171-2.458,13.142,13.142,0,0,0,1.661-2.044,13.14,13.14,0,0,0-1.661-2.044A7.463,7.463,0,0,0,8,6.626,7.462,7.462,0,0,0,2.834,9.084a13.14,13.14,0,0,0-1.661,2.044Z" transform="translate(0 -5.625)" fill={ color } fillRule="evenodd"/>
                    <path id="Trazado_259" data-name="Trazado 259" d="M13.627,11.126a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,13.627,11.126Zm-3.5,2.5a3.5,3.5,0,1,1,3.5,3.5A3.5,3.5,0,0,1,10.125,13.627Z" transform="translate(-5.622 -8.124)" fill={ color } fillRule="evenodd"/>
                </g>
            </g>
        </svg>
    );
}

export const DurationIcon = ({color = "#535af1"}) => {
    return (
        <svg id="timelapse" xmlns="http://www.w3.org/2000/svg" width="25.25" height="25.25" viewBox="0 0 25.25 25.25">
            <path id="Trazado_272" data-name="Trazado 272" d="M16.256,23.568a8.009,8.009,0,0,1-5.681-2.353l5.681-5.681V7.5a8.034,8.034,0,1,1,0,16.068Z" transform="translate(-3.631 -2.909)" fill={ color }/>
            <path id="Trazado_273" data-name="Trazado 273" d="M26.75,14.125A12.625,12.625,0,1,1,14.125,1.5,12.625,12.625,0,0,1,26.75,14.125Zm-2.3,0A10.329,10.329,0,1,1,14.125,3.8,10.329,10.329,0,0,1,24.454,14.125Z" transform="translate(-1.5 -1.5)" fill={ color } fillRule="evenodd"/>
        </svg>

    );
}

export const ComplementaryServicesIcon = ({color = "#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.705" height="25.992" viewBox="0 0 29.705 25.992">
            <g id="textarea" transform="translate(0 -2.25)">
                <path id="Trazado_811" data-name="Trazado 811" d="M25.992,19.07a1.857,1.857,0,1,0-1.857-1.857A1.857,1.857,0,0,0,25.992,19.07Zm0,1.857a3.713,3.713,0,1,0-3.713-3.713A3.713,3.713,0,0,0,25.992,20.926ZM3.713,19.07a1.857,1.857,0,1,0-1.857-1.857A1.857,1.857,0,0,0,3.713,19.07Zm0,1.857A3.713,3.713,0,1,0,0,17.213,3.713,3.713,0,0,0,3.713,20.926Z" transform="translate(0 -1.967)" fill={ color } fillRule="evenodd"/>
                <path id="Trazado_812" data-name="Trazado 812" d="M3.375,5.035A2.785,2.785,0,0,1,6.16,2.25H24.726A2.785,2.785,0,0,1,27.51,5.035v7.426H25.654V5.035a.928.928,0,0,0-.928-.928H6.16a.928.928,0,0,0-.928.928v7.426H3.375Zm1.857,13v7.426a.928.928,0,0,0,.928.928H24.726a.928.928,0,0,0,.928-.928V18.031H27.51v7.426a2.785,2.785,0,0,1-2.785,2.785H6.16a2.785,2.785,0,0,1-2.785-2.785V18.031Z" transform="translate(-0.59)" fill={ color } fillRule="evenodd"/>
            </g>
        </svg>
    );
}

export const VariantesIcon = ({color = "#535af1", width="24.996", height="21.873"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24.996 21.873">
            <g id="shuffle" transform="translate(0 -2.248)">
                <path id="Trazado_813" data-name="Trazado 813" d="M28.353,2.478a.781.781,0,0,1,1.106,0l3.905,3.905a.781.781,0,0,1,0,1.106l-3.905,3.905a.782.782,0,0,1-1.106-1.106l3.353-3.353L28.352,3.583a.781.781,0,0,1,0-1.106Zm0,12.5a.781.781,0,0,1,1.106,0l3.905,3.905a.781.781,0,0,1,0,1.106l-3.905,3.905a.782.782,0,0,1-1.106-1.106l3.353-3.353-3.354-3.353a.781.781,0,0,1,0-1.106Z" transform="translate(-8.597)" fill={ color } fillRule="evenodd"/>
                <path id="Trazado_814" data-name="Trazado 814" d="M0,8.656a.781.781,0,0,1,.781-.781H3.905c4.769,0,7.13,3.527,9.148,6.6l.125.192A22.434,22.434,0,0,0,16.2,18.635a5.712,5.712,0,0,0,4.112,1.737h3.124a.781.781,0,0,1,0,1.562H20.308a7.26,7.26,0,0,1-5.188-2.168,23.431,23.431,0,0,1-3.21-4.185l-.164-.249c-2.083-3.174-4.018-5.9-7.841-5.9H.781A.781.781,0,0,1,0,8.656Z" transform="translate(0 -1.72)" fill={ color } fillRule="evenodd"/>
                <path id="Trazado_815" data-name="Trazado 815" d="M0,21.153a.781.781,0,0,0,.781.781H3.905c4.769,0,7.13-3.527,9.148-6.6l.125-.192A22.434,22.434,0,0,1,16.2,11.174a5.713,5.713,0,0,1,4.112-1.737h3.124a.781.781,0,0,0,0-1.562H20.308a7.26,7.26,0,0,0-5.188,2.168,23.431,23.431,0,0,0-3.21,4.185l-.164.249c-2.083,3.174-4.018,5.9-7.841,5.9H.781A.781.781,0,0,0,0,21.153Z" transform="translate(0 -1.72)" fill={ color } fillRule="evenodd"/>
            </g>
        </svg>
    );
}

export const CategoriesIcon = ({color="#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.148" height="16.534" viewBox="0 0 23.148 16.534">
            <g id="list-ul" transform="translate(-2.25 -5.625)">
                <path id="Trazado_809" data-name="Trazado 809" d="M8.864,20.506a.827.827,0,0,1,.827-.827H24.571a.827.827,0,0,1,0,1.653H9.69A.827.827,0,0,1,8.864,20.506Zm0-6.614a.827.827,0,0,1,.827-.827H24.571a.827.827,0,0,1,0,1.653H9.69A.827.827,0,0,1,8.864,13.892Zm0-6.614a.827.827,0,0,1,.827-.827H24.571a.827.827,0,0,1,0,1.653H9.69A.827.827,0,0,1,8.864,7.278ZM3.9,8.932A1.653,1.653,0,1,0,2.25,7.278,1.653,1.653,0,0,0,3.9,8.932Zm0,6.614A1.653,1.653,0,1,0,2.25,13.892,1.653,1.653,0,0,0,3.9,15.546Zm0,6.614A1.653,1.653,0,1,0,2.25,20.506,1.653,1.653,0,0,0,3.9,22.159Z" fill={ color } fillRule="evenodd"/>
            </g>
        </svg>
    );
}

export const PrepayIcon = ({color="#7b7b7b"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.037" height="18.139" viewBox="0 0 13.037 18.139">
            <path id="euro" d="M12.573,15.188l.451,2.048a.377.377,0,0,1-.039.29.409.409,0,0,1-.219.187l-.064.013a.614.614,0,0,1-.135.045q-.084.019-.206.058a2.846,2.846,0,0,1-.277.071q-.155.032-.329.064t-.386.064q-.213.032-.432.058t-.47.039q-.251.013-.5.013A8.57,8.57,0,0,1,4.7,16.458a8.323,8.323,0,0,1-3.066-4.529H.412a.4.4,0,0,1-.29-.122A.4.4,0,0,1,0,11.517V10.061a.4.4,0,0,1,.122-.29.4.4,0,0,1,.29-.122h.85q-.026-.734.013-1.353H.412A.4.4,0,0,1,0,7.884V6.415A.4.4,0,0,1,.412,6H1.675A8.393,8.393,0,0,1,4.812,1.649,8.568,8.568,0,0,1,9.971,0a10.27,10.27,0,0,1,2.5.3.491.491,0,0,1,.257.193A.427.427,0,0,1,12.766.8l-.554,2.048a.379.379,0,0,1-.18.251.368.368,0,0,1-.309.032l-.051-.013q-.051-.013-.148-.032L11.3,3.04,11.008,3l-.335-.039L10.3,2.924l-.38-.013a5.284,5.284,0,0,0-2.911.824A5.093,5.093,0,0,0,5.076,6H11.1a.422.422,0,0,1,.412.49l-.309,1.469A.376.376,0,0,1,10.8,8.3H4.509a11.546,11.546,0,0,0,0,1.353h5.913a.4.4,0,0,1,.322.155.418.418,0,0,1,.077.348L10.512,11.6a.417.417,0,0,1-.4.335H5.127a5.189,5.189,0,0,0,1.926,2.39A5.133,5.133,0,0,0,10,15.2q.232,0,.464-.019t.432-.045q.2-.026.38-.058t.315-.064l.238-.058.155-.039.064-.026a.385.385,0,0,1,.335.026A.4.4,0,0,1,12.573,15.188Z" fill={color}/>
        </svg>
    );
}

export const OutStockIcon = ({color="#7b7b7b"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.486" height="22.933" viewBox="0 0 22.486 22.933">
            <g id="shield-slash" transform="translate(-1.454 -1.125)">
                <path id="Trazado_816" data-name="Trazado 816" d="M3,5.678A18.4,18.4,0,0,0,6.7,19.3a17.11,17.11,0,0,0,3.695,3.516,10.644,10.644,0,0,0,1.54.9,3.176,3.176,0,0,0,1.217.344,3.166,3.166,0,0,0,1.217-.344,10.644,10.644,0,0,0,1.54-.9,16.171,16.171,0,0,0,2.3-1.931l-1.081-1.081a14.817,14.817,0,0,1-2.083,1.754,9.072,9.072,0,0,1-1.31.764,3.313,3.313,0,0,1-.431.168,1.117,1.117,0,0,1-.149.037A.878.878,0,0,1,13,22.493a3.475,3.475,0,0,1-.431-.17,9.072,9.072,0,0,1-1.31-.764,15.589,15.589,0,0,1-3.359-3.2A16.665,16.665,0,0,1,4.42,7.1L3,5.677Zm16.9,10.411a17.4,17.4,0,0,0,1.815-10.9.694.694,0,0,0-.482-.56c-.957-.306-2.571-.8-4.166-1.226a19.647,19.647,0,0,0-3.909-.751A19.623,19.623,0,0,0,9.242,3.4q-.8.212-1.591.443L6.418,2.615q1.211-.363,2.432-.688a20.5,20.5,0,0,1,4.3-.8,20.515,20.515,0,0,1,4.3.8q2.133.573,4.238,1.246a2.224,2.224,0,0,1,1.534,1.81,18.846,18.846,0,0,1-2.208,12.229l-1.124-1.124Z" transform="translate(-0.455)" fill="#7b7b7b"/>
                <path id="Trazado_817" data-name="Trazado 817" d="M22.857,23.939l-21.4-21.4L2.536,1.453l21.4,21.4-1.081,1.081Z" transform="translate(0 -0.105)" fill={color} fillRule="evenodd"/>
            </g>
        </svg>
    );
}

export const DescuentoStarIcon = ({color="#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.127" height="17.278" viewBox="0 0 18.127 17.278">
            <path id="star" d="M12.563,17.168,7.58,19.778l.951-5.527L4.5,10.336l5.571-.806L12.563,4.5l2.492,5.029,5.571.806-4.032,3.916.951,5.527Z" transform="translate(-3.5 -3.5)" fill={ color } stroke={ color } strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </svg>
    );
}

export const DescuentoAlertIcon = ({color="#535af1"}) => {
    return (
        <svg id="danger" xmlns="http://www.w3.org/2000/svg" width="11.022" height="11.022" viewBox="0 0 11.022 11.022">
            <path id="Trazado_268" data-name="Trazado 268" d="M17.051,9a.551.551,0,0,1,.551.551v3.307a.551.551,0,1,1-1.1,0V9.551A.551.551,0,0,1,17.051,9Z" transform="translate(-11.54 -6.796)" fill={ color }/>
            <path id="Trazado_269" data-name="Trazado 269" d="M17.051,24a.551.551,0,1,0,.551.551A.551.551,0,0,0,17.051,24Z" transform="translate(-11.54 -16.285)" fill={ color }/>
            <path id="Trazado_270" data-name="Trazado 270" d="M8.511,3a5.511,5.511,0,1,0,5.511,5.511A5.511,5.511,0,0,0,8.511,3ZM4.1,8.511A4.409,4.409,0,1,0,8.511,4.1,4.409,4.409,0,0,0,4.1,8.511Z" transform="translate(-3 -3)" fill={ color } fillRule="evenodd"/>
        </svg>
    );
}

export const CaducidadIcon = ({color="#535af1"}) => {
    return (
        <svg id="danger" xmlns="http://www.w3.org/2000/svg" width="24.002" height="24.002" viewBox="0 0 24.002 24.002">
            <path id="Trazado_268" data-name="Trazado 268" d="M17.54,9a1.04,1.04,0,0,1,1.04,1.04v6.241a1.04,1.04,0,1,1-2.08,0V10.04A1.04,1.04,0,0,1,17.54,9Z" transform="translate(-5.539 -3.773)" fill={ color }/>
            <path id="Trazado_269" data-name="Trazado 269" d="M17.54,24a1.04,1.04,0,1,0,1.04,1.04A1.04,1.04,0,0,0,17.54,24Z" transform="translate(-5.539 -6.95)" fill={ color }/>
            <path id="Trazado_270" data-name="Trazado 270" d="M15,3A12,12,0,1,0,27,15,12,12,0,0,0,15,3ZM5.4,15A9.6,9.6,0,1,0,15,5.4,9.6,9.6,0,0,0,5.4,15Z" transform="translate(-3 -3)" fill={ color } fillRule="evenodd"/>
        </svg>
    );
}

export const ProfesionalIcon = ({color="#535af1"}) => {
    return (
        <svg id="person" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <path id="Trazado_680" data-name="Trazado 680" d="M27.417,29.5A2.04,2.04,0,0,0,29.5,27.417c0-2.083-2.083-8.333-12.5-8.333S4.5,25.333,4.5,27.417A2.04,2.04,0,0,0,6.583,29.5ZM6.594,27.533v0Zm.035-.117H27.37l.03,0,.017,0a5.563,5.563,0,0,0-1.733-3.467c-1.358-1.357-3.915-2.774-8.683-2.774s-7.325,1.417-8.683,2.775a5.578,5.578,0,0,0-1.733,3.467Zm20.78.117v0ZM17,14.917a4.167,4.167,0,1,0-4.167-4.167A4.167,4.167,0,0,0,17,14.917Zm6.25-4.167A6.25,6.25,0,1,1,17,4.5a6.25,6.25,0,0,1,6.25,6.25Z" transform="translate(-4.5 -4.5)" fill={ color } fillRule="evenodd"/>
        </svg>
    );
}

export const BirthdateIcon = ({color="#535af1"}) => {
    return (
        <svg id="calendar-date" xmlns="http://www.w3.org/2000/svg" width="26.172" height="26.172" viewBox="0 0 26.172 26.172">
            <path id="Trazado_818" data-name="Trazado 818" d="M13.306,22.958V14.232H12.271a20.61,20.61,0,0,0-2.146,1.319v1.137c.614-.42,1.585-1.014,2.057-1.271h.02v7.541h1.1Zm1.943-2.134a2.6,2.6,0,0,0,2.786,2.3c2.057,0,3.271-1.744,3.271-4.7,0-3.163-1.277-4.364-3.194-4.364a2.875,2.875,0,0,0-2.939,2.959,2.712,2.712,0,0,0,2.742,2.895,2.279,2.279,0,0,0,2.262-1.293h.044c-.007,2.153-.754,3.54-2.134,3.54a1.619,1.619,0,0,1-1.718-1.341H15.25Zm4.83-3.79a1.917,1.917,0,0,1-1.937,1.93A1.8,1.8,0,0,1,16.272,17a1.85,1.85,0,0,1,1.911-1.98,1.822,1.822,0,0,1,1.9,2.012Z" transform="translate(-2.764 -3.839)" fill={ color }/>
            <path id="Trazado_819" data-name="Trazado 819" d="M1.636,7.157V23.515A1.636,1.636,0,0,0,3.271,25.15H22.9a1.636,1.636,0,0,0,1.636-1.636V7.157ZM3.271,2.25A3.271,3.271,0,0,0,0,5.521V23.515a3.271,3.271,0,0,0,3.271,3.271H22.9a3.271,3.271,0,0,0,3.271-3.271V5.521A3.271,3.271,0,0,0,22.9,2.25Z" transform="translate(0 -0.614)" fill={ color } fillRule="evenodd"/>
            <path id="Trazado_820" data-name="Trazado 820" d="M7.568,0a.818.818,0,0,1,.818.818v.818a.818.818,0,0,1-1.636,0V.818A.818.818,0,0,1,7.568,0ZM22.29,0a.818.818,0,0,1,.818.818v.818a.818.818,0,0,1-1.636,0V.818A.818.818,0,0,1,22.29,0Z" transform="translate(-1.843)" fill={ color } fillRule="evenodd"/>
        </svg>
    );
}

export const HorarioEspecialIcon = ({color="#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.809" height="25.809" viewBox="0 0 25.809 25.809">
            <path id="calendar" d="M11.669,5.581H23.14V3h2.868V5.581h1.434a2.736,2.736,0,0,1,2.868,2.581V26.228a2.736,2.736,0,0,1-2.868,2.581H7.368A2.736,2.736,0,0,1,4.5,26.228V8.162A2.736,2.736,0,0,1,7.368,5.581H8.8V3h2.868Zm-4.3,5.162V26.228H27.441V10.743Zm2.868,3.871H13.1v2.581H10.235Zm5.735,0h2.868v2.581H15.97Zm5.735,0h2.868v2.581H21.706Zm0,5.162h2.868v2.581H21.706Zm-5.735,0h2.868v2.581H15.97Zm-5.735,0H13.1v2.581H10.235Z" transform="translate(-4.5 -3)" fill={ color } fillRule="evenodd"/>
        </svg>
    );
}

export const DeleteIcon = ({color="#535af1", background="#eff5fd"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Grupo_1595" data-name="Grupo 1595" transform="translate(-218 -449)">
            <circle id="Elipse_479" data-name="Elipse 479" cx="10" cy="10" r="10" transform="translate(218 449)" fill={ background }/>
            <g id="Grupo_1591" data-name="Grupo 1591">
            <line id="Línea_455" data-name="Línea 455" x2="7" y2="7" transform="translate(224.5 455.5)" fill="none" stroke={ color } strokeLinecap="round" strokeWidth="1.5"/>
            <line id="Línea_456" data-name="Línea 456" x1="7" y2="7" transform="translate(224.5 455.5)" fill="none" stroke={ color } strokeLinecap="round" strokeWidth="1.5"/>
            </g>
        </g>
        </svg>
    );
}

export const PagoAdelantadoIcon = ({color="#fdfeae"}) => {
    return (
        <svg id="Grupo_1619" data-name="Grupo 1619" xmlns="http://www.w3.org/2000/svg" width="29.222" height="29.222" viewBox="0 0 29.222 29.222">
            <g id="Grupo_1618" data-name="Grupo 1618">
                <path id="Trazado_919" data-name="Trazado 919" d="M120.733,88.617q0,2.006,0,4.012c0,.461.171.632.628.632h8.918c.457,0,.628-.173.628-.632q0-2.006,0-4.012v-.206H120.733Zm4.486,1.026a.423.423,0,0,1,.212-.052q1.959,0,3.919,0a.327.327,0,0,1,.367.331c-.005.192-.151.316-.384.317-.647,0-1.294,0-1.941,0h-1.978A.315.315,0,0,1,125.08,90a.292.292,0,0,1,.139-.353m.189,1.745c.659,0,1.319,0,1.978,0s1.319,0,1.978,0c.144,0,.275.048.312.186a.458.458,0,0,1-.036.326.357.357,0,0,1-.266.13c-1.325.008-2.65.007-3.975,0a.322.322,0,1,1,.008-.643" transform="translate(-107.962 -79.06)" fill="#fdfeae"/>
                <path id="Trazado_920" data-name="Trazado 920" d="M64.989,154.224a2.817,2.817,0,1,0-2.809,2.833,2.809,2.809,0,0,0,2.809-2.833m-4.006.874a1.611,1.611,0,0,1-.128-.376H60.45v-.31h.363c0-.057-.006-.114-.006-.174,0-.038,0-.076,0-.113h-.36v-.305h.394a1.619,1.619,0,0,1,.144-.442,1.288,1.288,0,0,1,.51-.548,1.521,1.521,0,0,1,.775-.19,1.674,1.674,0,0,1,.555.087,1.182,1.182,0,0,1,.423.249.878.878,0,0,1,.244.4l-.592.268a.472.472,0,0,0-.211-.331.73.73,0,0,0-.395-.106.713.713,0,0,0-.421.118.7.7,0,0,0-.249.35,1.251,1.251,0,0,0-.041.148H62.7l-.1.305h-1.05c0,.037,0,.074,0,.113,0,.061,0,.118.007.174h.961l-.1.31H61.6a.961.961,0,0,0,.12.282.642.642,0,0,0,.58.268.619.619,0,0,0,.39-.12.6.6,0,0,0,.207-.364l.616.183a1.1,1.1,0,0,1-.242.479,1.063,1.063,0,0,1-.428.289,1.6,1.6,0,0,1-.566.1,1.549,1.549,0,0,1-.789-.19,1.259,1.259,0,0,1-.505-.548" transform="translate(-53.077 -135.408)" fill="#fdfeae"/>
                <path id="Trazado_921" data-name="Trazado 921" d="M14.611,0A14.611,14.611,0,1,0,29.222,14.611,14.611,14.611,0,0,0,14.611,0M9.7,5.053h8.735a1.262,1.262,0,0,1,.756.5.332.332,0,0,1-.05.467.313.313,0,0,1-.471-.082.573.573,0,0,0-.5-.235q-4.078,0-8.156,0c-.418,0-.587.171-.587.592q0,3.789,0,7.579a1.3,1.3,0,0,1-.011.223.326.326,0,0,1-.326.276.333.333,0,0,1-.315-.292,1.5,1.5,0,0,1-.005-.2c0-2.464.012-4.928-.008-7.392A1.3,1.3,0,0,1,9.7,5.053M5.629,18.825A3.471,3.471,0,1,1,9.083,22.3a3.473,3.473,0,0,1-3.454-3.477m12.486,5.343H9.978A1.151,1.151,0,0,1,8.837,23.4c-.087-.248-.027-.43.166-.5s.348.02.444.271a.487.487,0,0,0,.464.341c.056,0,.112,0,.168,0h8.006c.493,0,.649-.156.649-.644,0-.36,0-.721,0-1.11H12.872a1.675,1.675,0,0,1-.242-.01.319.319,0,0,1-.277-.323.329.329,0,0,1,.29-.315,1.719,1.719,0,0,1,.2,0h5.886V20.88q0-2.492,0-4.984a1.241,1.241,0,0,1,.005-.186.32.32,0,0,1,.323-.279.3.3,0,0,1,.314.262,1.033,1.033,0,0,1,.013.2q0,3.5,0,7a1.158,1.158,0,0,1-1.276,1.271m-4.669-1.516a.312.312,0,0,1,.294-.325,5.566,5.566,0,0,1,.686,0,.3.3,0,0,1,.284.329.317.317,0,0,1-.307.312c-.111.008-.223,0-.334,0s-.211.007-.316,0a.326.326,0,0,1-.306-.317m8.935-7.8q-4.525,0-9.05,0a1.136,1.136,0,0,1-1.207-1.2q0-2.883,0-5.767a1.129,1.129,0,0,1,1.193-1.2c1.518,0,3.035,0,4.553,0s3.023,0,4.534,0a1.13,1.13,0,0,1,1.164.9,1.491,1.491,0,0,1,.024.315q0,2.865,0,5.73a1.136,1.136,0,0,1-1.211,1.217" fill="#fdfeae"/>
                <path id="Trazado_922" data-name="Trazado 922" d="M130.277,69.368h-8.846a1.588,1.588,0,0,0-.223.008.458.458,0,0,0-.426.425c-.02.3,0,.594,0,.9h10.165c0-.231,0-.447,0-.663,0-.51-.162-.673-.666-.673" transform="translate(-107.998 -62.031)" fill="#fdfeae"/>
            </g>
        </svg>

    );
}

export const PagoAdelantadoMiniIcon = ({color="#535af1", background="#ffffff"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.482" height="14.576" viewBox="0 0 16.482 14.576">
            <defs>
                <clipPath id="clip-path">
                <rect id="Rectángulo_1208" data-name="Rectángulo 1208" width="12.014" height="12.016" fill="#fff"/>
                </clipPath>
            </defs>
            <g id="Grupo_1623" data-name="Grupo 1623" transform="translate(-767.428 -386.475)">
                <g id="Grupo_1621" data-name="Grupo 1621" transform="translate(767.428 389.036)">
                <g id="Grupo_1620" data-name="Grupo 1620" clipPath="url(#clip-path)">
                    <path id="Trazado_923" data-name="Trazado 923" d="M5.977,12.016A6.008,6.008,0,1,0,0,6a6.02,6.02,0,0,0,5.977,6.017M1.124,6.034a4.875,4.875,0,1,1,4.888,4.854A4.883,4.883,0,0,1,1.124,6.034m6.131.926a1.03,1.03,0,0,1-.358.63,1.071,1.071,0,0,1-.675.207,1.112,1.112,0,0,1-1-.464,1.665,1.665,0,0,1-.208-.488H6.418l.171-.537H4.925c-.007-.1-.011-.2-.011-.3,0-.067,0-.132,0-.2H6.735l.179-.529H4.984a2.158,2.158,0,0,1,.072-.256,1.2,1.2,0,0,1,.431-.606,1.234,1.234,0,0,1,.728-.2A1.261,1.261,0,0,1,6.9,4.4a.816.816,0,0,1,.366.573l1.025-.464a1.518,1.518,0,0,0-.423-.687,2.045,2.045,0,0,0-.732-.431,2.9,2.9,0,0,0-.96-.15,2.632,2.632,0,0,0-1.342.329,2.227,2.227,0,0,0-.882.948,2.81,2.81,0,0,0-.25.765H3.018v.529h.622c0,.064,0,.129,0,.2,0,.1,0,.2.011.3H3.018v.537h.7a2.8,2.8,0,0,0,.222.651,2.178,2.178,0,0,0,.874.948,2.681,2.681,0,0,0,1.366.329,2.766,2.766,0,0,0,.98-.167,1.84,1.84,0,0,0,.74-.5,1.911,1.911,0,0,0,.419-.83Z" transform="translate(0 0)" fill={ background }/>
                </g>
                </g>
                <ellipse id="Elipse_480" data-name="Elipse 480" cx="4.469" cy="4.469" rx="4.469" ry="4.469" transform="translate(774.973 386.475)" fill={ background }/>
                <path id="Trazado_924" data-name="Trazado 924" d="M0,0,2.872,2.656,1.413,4.239" transform="translate(781.805 389.45) rotate(90)" fill="none" stroke={ color } strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            </g>
        </svg>
    );
}

export const AlertasIcon = ({color="#535af1", width="26.653"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ width } height={ width } viewBox="0 0 26.653 26.653">
            <path id="alertalt" d="M24.987,14.992H23.321a1.644,1.644,0,0,1-1.666-1.666,1.592,1.592,0,0,1,.495-1.185,1.621,1.621,0,0,1,1.171-.481h1.666a1.623,1.623,0,0,1,1.171.481,1.573,1.573,0,0,1,.495,1.171,1.639,1.639,0,0,1-.481,1.185A1.588,1.588,0,0,1,24.987,14.992Zm-3.2-7.965a1.513,1.513,0,0,1-1.106.469,1.449,1.449,0,0,1-1.08-.455,1.513,1.513,0,0,1-.443-1.093A1.556,1.556,0,0,1,19.6,4.841l1.093-1.067a1.43,1.43,0,0,1,1.08-.469,1.555,1.555,0,0,1,1.549,1.549,1.472,1.472,0,0,1-.443,1.08Zm-1.8,13.795a.845.845,0,0,1-.833.833H7.5a.845.845,0,0,1-.833-.833v-7.5A6.418,6.418,0,0,1,8.615,8.615a6.423,6.423,0,0,1,4.711-1.952,6.416,6.416,0,0,1,4.711,1.952,6.421,6.421,0,0,1,1.952,4.711ZM13.34,5A1.616,1.616,0,0,1,12.155,4.5a1.6,1.6,0,0,1-.495-1.171V1.666A1.611,1.611,0,0,1,12.142.481a1.7,1.7,0,0,1,2.369,0,1.608,1.608,0,0,1,.481,1.185V3.332A1.623,1.623,0,0,1,14.511,4.5,1.573,1.573,0,0,1,13.34,5ZM4.867,7.027,3.8,5.934a1.43,1.43,0,0,1-.469-1.08A1.555,1.555,0,0,1,4.88,3.3a1.432,1.432,0,0,1,1.08.469L7.054,4.84A1.557,1.557,0,0,1,7.5,5.946,1.513,1.513,0,0,1,5.973,7.47a1.563,1.563,0,0,1-1.106-.443ZM5,13.313A1.639,1.639,0,0,1,4.516,14.5a1.588,1.588,0,0,1-1.185.495H1.666A1.6,1.6,0,0,1,.495,14.5,1.616,1.616,0,0,1,0,13.313a1.573,1.573,0,0,1,.495-1.171,1.623,1.623,0,0,1,1.171-.481H3.332a1.611,1.611,0,0,1,1.185.481A1.59,1.59,0,0,1,5,13.313ZM1.666,23.321H24.987a1.623,1.623,0,0,1,1.171.481,1.666,1.666,0,0,1,0,2.369,1.623,1.623,0,0,1-1.171.481H1.666a1.623,1.623,0,0,1-1.171-.481,1.666,1.666,0,0,1,0-2.369A1.623,1.623,0,0,1,1.666,23.321Z" fill={ color }/>
        </svg>

    );
}

export const ObservacionesIcon = ({color="#ffffff"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.499" height="20.415" viewBox="0 0 17.499 20.415">
            <g id="file-text" transform="translate(-4.5 -2.25)">
                <g id="Grupo_1658" data-name="Grupo 1658">
                <path id="Trazado_937" data-name="Trazado 937" d="M7.416,2.25H19.082A2.916,2.916,0,0,1,22,5.166V19.749a2.916,2.916,0,0,1-2.916,2.916H7.416A2.916,2.916,0,0,1,4.5,19.749V5.166A2.916,2.916,0,0,1,7.416,2.25Zm0,1.458A1.458,1.458,0,0,0,5.958,5.166V19.749a1.458,1.458,0,0,0,1.458,1.458H19.082a1.458,1.458,0,0,0,1.458-1.458V5.166a1.458,1.458,0,0,0-1.458-1.458Z" fill={ color } fillRule="evenodd"/>
                <path id="Trazado_938" data-name="Trazado 938" d="M10.125,18.479a.729.729,0,0,1,.729-.729h4.375a.729.729,0,1,1,0,1.458H10.854A.729.729,0,0,1,10.125,18.479Zm0-2.916a.729.729,0,0,1,.729-.729H19.6a.729.729,0,1,1,0,1.458H10.854A.729.729,0,0,1,10.125,15.562Zm0-2.916a.729.729,0,0,1,.729-.729H19.6a.729.729,0,0,1,0,1.458H10.854A.729.729,0,0,1,10.125,12.646Zm0-2.916A.729.729,0,0,1,10.854,9H19.6a.729.729,0,0,1,0,1.458H10.854A.729.729,0,0,1,10.125,9.729Z" transform="translate(-1.979 -2.375)" fill={ color } fillRule="evenodd"/>
                </g>
            </g>
        </svg>
    );
}

export const EuroIcon = ({color="#ffffff", width="9.874", height="13.737"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9.874 13.737">
            <path id="euro" d="M9.522,11.5l.342,1.551a.285.285,0,0,1-.029.219.31.31,0,0,1-.166.142l-.049.01a.465.465,0,0,1-.1.034q-.063.014-.156.044a2.155,2.155,0,0,1-.21.054L8.9,13.6q-.132.024-.293.049t-.327.044q-.166.019-.356.029t-.375.01a6.49,6.49,0,0,1-3.99-1.273,6.3,6.3,0,0,1-2.322-3.43H.312a.3.3,0,0,1-.219-.093A.3.3,0,0,1,0,8.722V7.62A.3.3,0,0,1,.093,7.4a.3.3,0,0,1,.219-.093H.956q-.019-.556.01-1.025H.312A.3.3,0,0,1,0,5.971V4.859a.3.3,0,0,1,.312-.312h.956a6.356,6.356,0,0,1,2.376-3.3A6.489,6.489,0,0,1,7.551,0,7.778,7.778,0,0,1,9.444.224a.372.372,0,0,1,.195.147.324.324,0,0,1,.029.234L9.249,2.156a.287.287,0,0,1-.137.19.278.278,0,0,1-.234.024l-.039-.01-.112-.024L8.556,2.3l-.219-.034-.254-.029L7.8,2.215,7.512,2.2a4,4,0,0,0-2.2.624A3.857,3.857,0,0,0,3.844,4.546H8.41a.32.32,0,0,1,.312.371L8.488,6.03a.285.285,0,0,1-.312.254H3.415a8.744,8.744,0,0,0,0,1.025H7.893a.3.3,0,0,1,.244.117.316.316,0,0,1,.058.263L7.961,8.781a.316.316,0,0,1-.3.254H3.883a3.929,3.929,0,0,0,1.458,1.81,3.887,3.887,0,0,0,2.23.667q.176,0,.351-.014t.327-.034q.151-.019.288-.044t.239-.049l.18-.044.117-.029.049-.019a.292.292,0,0,1,.254.019A.3.3,0,0,1,9.522,11.5Z" fill={ color }/>
        </svg>

    );
}

export const CheckIcon = ({color="#96f8d2"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.57" height="14.57" viewBox="0 0 14.57 14.57">
            <g id="Grupo_1644" data-name="Grupo 1644" transform="translate(-785.139 -370.7)">
                <path id="Trazado_933" data-name="Trazado 933" d="M7.285,0A7.285,7.285,0,1,1,0,7.285,7.285,7.285,0,0,1,7.285,0Z" transform="translate(785.139 370.7)" fill={ color }/>
                <path id="Trazado_932" data-name="Trazado 932" d="M0,0,4.749,4.393,2.337,7.01" transform="translate(795.929 375.356) rotate(90)" fill="none" stroke="#393d40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6"/>
            </g>
        </svg>

    );
}

export const CommentsFilledIcon = ({color="#ffffff", background="#393d40"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.227" height="17.421" viewBox="0 0 18.227 17.421">
            <g id="Grupo_1642" data-name="Grupo 1642" transform="translate(-647.003 -259.155)">
                <g id="Grupo_1635" data-name="Grupo 1635" transform="translate(647.002 259.155)">
                <g id="chat-left-dots" transform="translate(0 0)">
                    <path id="Trazado_283" data-name="Trazado 283" d="M2.278,0A2.278,2.278,0,0,0,0,2.278V16.852a.57.57,0,0,0,.973.4L4.223,14a1.139,1.139,0,0,1,.806-.334H15.949a2.278,2.278,0,0,0,2.278-2.278V2.278A2.278,2.278,0,0,0,15.949,0Z" fill={ color } fillRule="evenodd"/>
                </g>
                </g>
                <circle id="Elipse_487" data-name="Elipse 487" cx="1" cy="1" r="1" transform="translate(652 265.094)" fill={ background }/>
                <circle id="Elipse_488" data-name="Elipse 488" cx="1" cy="1" r="1" transform="translate(655 265.094)" fill={ background }/>
                <circle id="Elipse_489" data-name="Elipse 489" cx="1" cy="1" r="1" transform="translate(658 265.094)" fill={ background }/>
            </g>
        </svg>

    );
}

export const FingerUpIcon = ({color="#eff5fd"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.321" height="18.057" viewBox="0 0 14.321 18.057">
            <path id="Trazado_289" data-name="Trazado 289" d="M10.192,2.312A1.788,1.788,0,0,1,12.383.681l.3.076a1.581,1.581,0,0,1,1.162,1.108,10.761,10.761,0,0,1,.071,5.18q.254-.035.509-.059a7.355,7.355,0,0,1,2.89.241,2.386,2.386,0,0,1,1.378,1.462,2.18,2.18,0,0,1-.269,1.99,2.536,2.536,0,0,1,.158.417,3.792,3.792,0,0,1,0,1.966,2.384,2.384,0,0,1-.184.464,1.864,1.864,0,0,1,0,1.318,3.633,3.633,0,0,1-.56,1.035,1.608,1.608,0,0,1,.087.534,2.141,2.141,0,0,1-.29,1.047,2.477,2.477,0,0,1-2.221,1.223V17.535a1.337,1.337,0,0,0,1.225-.643.993.993,0,0,0,.139-.478.3.3,0,0,0-.046-.2l-.406-.406.405-.406a2.541,2.541,0,0,0,.58-.923c.119-.358.05-.506-.006-.56l-.405-.406.405-.406a.839.839,0,0,0,.177-.362,2.623,2.623,0,0,0,0-1.334.875.875,0,0,0-.177-.362l-.406-.407.405-.406a1.067,1.067,0,0,0,.307-1.171,1.263,1.263,0,0,0-.656-.749,6.253,6.253,0,0,0-2.423-.188,10.23,10.23,0,0,0-1.257.2l-.016,0h0a.574.574,0,0,1-.683-.739,9.579,9.579,0,0,0,.166-5.428.434.434,0,0,0-.331-.293L12.1,1.8a.637.637,0,0,0-.766.595,13.886,13.886,0,0,1-.554,3.274,7.346,7.346,0,0,1-1.422,2.27,6.374,6.374,0,0,1-3,1.962.871.871,0,0,0-.712.747v4.6a.673.673,0,0,0,.635.632,6.062,6.062,0,0,1,2.861,1l.046.029a4.489,4.489,0,0,0,.891.451,3.94,3.94,0,0,0,1.309.187h4.02v1.149H11.391a5.05,5.05,0,0,1-1.683-.25,5.536,5.536,0,0,1-1.114-.556l-.055-.035a4.926,4.926,0,0,0-2.375-.829A1.823,1.823,0,0,1,4.5,15.238V10.644A2.008,2.008,0,0,1,6.058,8.789,5.246,5.246,0,0,0,8.507,7.17,6.286,6.286,0,0,0,9.7,5.288a12.829,12.829,0,0,0,.492-2.974Z" transform="translate(-4.5 -0.628)" fill={ color } fillRule="evenodd"/>
        </svg>
    );
}

export const FingerDownIcon = ({color="#448ff2"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.017" height="20.191" viewBox="0 0 16.017 20.191">
            <g id="hand-thumbs-down" transform="translate(-4.5 -0.63)">
                <path id="Trazado_292" data-name="Trazado 292" d="M10.866,18.938a2,2,0,0,0,2.451,1.824l.335-.083a1.77,1.77,0,0,0,1.3-1.239,12.036,12.036,0,0,0,.08-5.792c.175.026.366.047.569.066a8.217,8.217,0,0,0,3.232-.271,2.666,2.666,0,0,0,1.541-1.634,2.435,2.435,0,0,0-.3-2.227,2.805,2.805,0,0,0,.177-.465,4.245,4.245,0,0,0,0-2.2,2.69,2.69,0,0,0-.206-.518,2.088,2.088,0,0,0,0-1.476,4.061,4.061,0,0,0-.627-1.156,1.807,1.807,0,0,0,.1-.6A2.389,2.389,0,0,0,19.186,2,2.767,2.767,0,0,0,16.7.63V1.914a1.5,1.5,0,0,1,1.369.719,1.109,1.109,0,0,1,.155.534.339.339,0,0,1-.051.219l-.454.453.453.454a2.86,2.86,0,0,1,.648,1.034c.134.4.055.565-.006.627l-.453.453.453.454a.954.954,0,0,1,.2.4,2.938,2.938,0,0,1,0,1.493.986.986,0,0,1-.2.4l-.453.453.453.454a1.2,1.2,0,0,1,.343,1.311,1.406,1.406,0,0,1-.734.836,6.971,6.971,0,0,1-2.71.211,11.44,11.44,0,0,1-1.405-.219l-.018-.005h-.005a.642.642,0,0,0-.764.826,10.712,10.712,0,0,1,.186,6.069.483.483,0,0,1-.37.328L13,19.515a.713.713,0,0,1-.857-.667,15.545,15.545,0,0,0-.619-3.661,8.229,8.229,0,0,0-1.59-2.539,7.138,7.138,0,0,0-3.355-2.192.973.973,0,0,1-.8-.835V4.482a.753.753,0,0,1,.71-.707,6.778,6.778,0,0,0,3.2-1.116l.051-.031a4.96,4.96,0,0,1,1-.5,4.376,4.376,0,0,1,1.464-.21h4.5V.63h-4.5a5.651,5.651,0,0,0-1.883.279,6.2,6.2,0,0,0-1.246.623l-.062.037A5.5,5.5,0,0,1,6.36,2.5,2.036,2.036,0,0,0,4.5,4.482V9.62A2.245,2.245,0,0,0,6.243,11.7a5.867,5.867,0,0,1,2.738,1.811,7.033,7.033,0,0,1,1.335,2.1,14.359,14.359,0,0,1,.55,3.328Z" fill={ color } fillRule="evenodd"/>
            </g>
        </svg>
    );
}

export const PeriodCalendarIcon = ({color="#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.269" height="17.625" viewBox="0 0 16.269 17.625">
            <path id="calendar" d="M19.413,3.606H16.7V2.25H15.346V3.606H9.923V2.25H8.567V3.606H5.856A1.359,1.359,0,0,0,4.5,4.962V18.519a1.359,1.359,0,0,0,1.356,1.356H19.413a1.359,1.359,0,0,0,1.356-1.356V4.962A1.359,1.359,0,0,0,19.413,3.606Zm0,14.913H5.856V9.029H19.413Zm0-10.846H5.856V4.962H8.567V6.317H9.923V4.962h5.423V6.317H16.7V4.962h2.712Z" transform="translate(-4.5 -2.25)" fill={ color }/>
        </svg>

    );
}

export const PeriodTimeIcon = ({color="#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.847" height="15.847" viewBox="0 0 15.847 15.847">
            <g id="time" transform="translate(-3 -3)">
                <path id="Trazado_888" data-name="Trazado 888" d="M13.5,10.5h1.585v3.962h3.962v1.585H13.5Z" transform="translate(-4.954 -3.538)" fill={ color }/>
                <path id="Trazado_889" data-name="Trazado 889" d="M18.847,10.923A7.923,7.923,0,1,1,10.923,3,7.924,7.924,0,0,1,18.847,10.923Zm-1.585,0a6.339,6.339,0,1,1-6.339-6.339,6.339,6.339,0,0,1,6.339,6.339Z" fill={ color } fillRule="evenodd"/>
            </g>
        </svg>
    );
}

export const BellIcon = ({color="#535af1"}) => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 14C5.60254 14 4.875 13.3732 4.875 12.6H8.125C8.125 13.3732 7.39746 14 6.5 14ZM13 11.9H0V10.5L1.625 9.8V5.95C1.625 3.5266 2.77956 1.9551 4.875 1.526V0H8.125V1.526C10.2204 1.9544 11.375 3.5252 11.375 5.95V9.8L13 10.5V11.9ZM6.5 2.625C5.50848 2.56984 4.55226 2.95209 3.96094 3.64C3.45519 4.32919 3.20705 5.13547 3.25 5.95V10.5H9.75V5.95C9.7929 5.13547 9.54476 4.32921 9.03906 3.64C8.44774 2.95209 7.49152 2.56984 6.5 2.625Z" fill={ color }/>
        </svg>

    );
}

export const UserIcon = ({color="#535af1", width='15', height='15'}) => {
    return (
        <svg width={ width } height={ height } viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 13.125V11.875C12.5 10.4943 11.3807 9.375 10 9.375H5C3.61929 9.375 2.5 10.4943 2.5 11.875V13.125" stroke={ color } strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 6.875C8.88071 6.875 10 5.75571 10 4.375C10 2.99429 8.88071 1.875 7.5 1.875C6.11929 1.875 5 2.99429 5 4.375C5 5.75571 6.11929 6.875 7.5 6.875Z" stroke={ color } strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export const FooterHome = ({ color = 'white', active = true, width = '24', height = '24' }) => (
    <svg width={ width } height={ height } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g style={{ mixBlendMode: `${ active ? 'normal' : 'multiply' }` }}>
        <path fillRule="evenodd" clipRule="evenodd" d="M3 9L12 2L21 9V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V9Z" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
);

export const FooterReservas = ({ color = 'white', active = false, width = '24', height = '24' }) => (
    <svg width={ width } height={ height } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g style={{ mixBlendMode: `${ active ? 'normal' : 'multiply' }` }}>
            <path fillRule="evenodd" clipRule="evenodd" d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V6Z" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 2V6" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 2V6" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 10H21" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
);

export const FooterAdd = ({ color = 'white', width = '24', height = '24' }) => (
    <svg width={ width } height={ height } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill={ color }/>
    </svg>

);

export const FooterProductos  = ({ color = 'white', active = false, width = '23', height = '25' }) => (
    <svg width={ width } height={ height } viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{ mixBlendMode: `${ active ? 'normal' : 'multiply' }`}}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.80887 9.95542C3.99584 9.02058 4.81667 8.34766 5.77003 8.34766H17.0995C18.0528 8.34766 18.8737 9.02058 19.0606 9.95542L21.3911 21.6076C21.6386 22.8451 20.692 23.9998 19.4299 23.9998H3.43961C2.17751 23.9998 1.23093 22.8451 1.47845 21.6076L3.80887 9.95542Z" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.7427 12.3682L14.742 4.54327C14.7112 2.76939 13.2644 1.3475 11.4902 1.3475C9.71608 1.3475 8.26927 2.76938 8.23844 4.54327L8.24337 12.364" stroke={ color } strokeWidth="2" strokeLinecap="round"/>
    </g>
    </svg>
);

export const FooterBonos  = ({ color = 'white', active = false, width = '24', height = '24' }) => (
    <svg width={ width } height={ height } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g style={{ mixBlendMode: `${ active ? 'normal' : 'multiply' }`}}>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.59 13.41L13.42 20.58C13.0449 20.9556 12.5358 21.1666 12.005 21.1666C11.4742 21.1666 10.9651 20.9556 10.59 20.58L2 12V2H12L20.59 10.59C21.3654 11.3701 21.3654 12.6299 20.59 13.41Z" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="8.5" cy="8.5" r="1.5" fill={ color }/>
        </g>
    </svg>
);

export const CustomizeArrowRight = ({color="#535af1", width = '6', height = '8'}) => {
    return (
        <svg width={ width } height={ height } viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.80251 6.17383L4.0542 3.83636L1.80251 1.49889" stroke={ color } strokeWidth="2" strokeLinecap="round"/>
        </svg>
    );
}

export const CartIcon = ({color="#535af1", width = '8', height = '9'}) => {
    return (
        <svg width={ width } height={ height } viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.95011 4.83995C6.21689 4.83995 6.45165 4.69406 6.57259 4.47344L7.846 2.16408C7.97761 1.92923 7.80687 1.63745 7.53654 1.63745H2.27216L1.9378 0.925781H0.774658V1.63745H1.48606L2.76658 4.33822L2.28639 5.20646C2.02673 5.68327 2.3682 6.26328 2.90887 6.26328H7.17728V5.55161H2.90887L3.30014 4.83995H5.95011ZM2.61008 2.34911H6.93185L5.95011 4.12828H3.45309L2.61008 2.34911ZM2.90887 6.61911C2.51759 6.61911 2.20102 6.93936 2.20102 7.33078C2.20102 7.7222 2.51759 8.04245 2.90887 8.04245C3.30014 8.04245 3.62027 7.7222 3.62027 7.33078C3.62027 6.93936 3.30014 6.61911 2.90887 6.61911ZM6.46588 6.61911C6.07461 6.61911 5.75803 6.93936 5.75803 7.33078C5.75803 7.7222 6.07461 8.04245 6.46588 8.04245C6.85715 8.04245 7.17728 7.7222 7.17728 7.33078C7.17728 6.93936 6.85715 6.61911 6.46588 6.61911Z" fill={ color } />
        </svg>

    );
}

export const DndIcon = ({color="#535af1"}) => {
    return (
        <svg id="arrows-move" xmlns="http://www.w3.org/2000/svg" width="20.189" height="20.189" viewBox="0 0 20.189 20.189">
            <path id="Trazado_942" data-name="Trazado 942" d="M10.18,17.6a.721.721,0,0,0-.721-.721H2.971a.721.721,0,0,0,0,1.442H9.459A.721.721,0,0,0,10.18,17.6Z" transform="translate(-2.248 -7.502)" fill={ color } fillRule="evenodd"/>
            <path id="Trazado_943" data-name="Trazado 943" d="M6.364,12.585a.721.721,0,0,0-1.021,0L2.459,15.469a.721.721,0,0,0,0,1.021l2.884,2.884a.722.722,0,0,0,1.021-1.021L3.99,15.979l2.375-2.374a.721.721,0,0,0,0-1.021ZM14.5,15.979a.721.721,0,0,1,.721-.721h6.488a.721.721,0,0,1,0,1.442H15.225A.721.721,0,0,1,14.5,15.979Z" transform="translate(-2.247 -5.885)" fill={ color } fillRule="evenodd"/>
            <path id="Trazado_944" data-name="Trazado 944" d="M23.574,12.585a.721.721,0,0,1,1.021,0l2.884,2.884a.721.721,0,0,1,0,1.021l-2.884,2.884a.722.722,0,0,1-1.021-1.021l2.374-2.374-2.375-2.374a.721.721,0,0,1,0-1.021ZM17.6,18.142a.721.721,0,0,0-.721.721v6.488a.721.721,0,0,0,1.442,0V18.863A.721.721,0,0,0,17.6,18.142Z" transform="translate(-7.502 -5.885)" fill={ color } fillRule="evenodd"/>
            <path id="Trazado_945" data-name="Trazado 945" d="M12.585,18.321a.721.721,0,0,0,0,1.021l2.884,2.884a.721.721,0,0,0,1.021,0l2.884-2.884a.722.722,0,0,0-1.021-1.021l-2.374,2.374L13.605,18.32a.721.721,0,0,0-1.021,0Zm3.394-8.141a.721.721,0,0,1-.721-.721V2.971a.721.721,0,0,1,1.442,0V9.459A.721.721,0,0,1,15.979,10.18Z" transform="translate(-5.885 -2.248)" fill={ color } fillRule="evenodd"/>
            <path id="Trazado_946" data-name="Trazado 946" d="M12.585,6.364a.721.721,0,0,1,0-1.021l2.884-2.884a.721.721,0,0,1,1.021,0l2.884,2.884a.722.722,0,0,1-1.021,1.021L15.979,3.99,13.605,6.364a.721.721,0,0,1-1.021,0Z" transform="translate(-5.885 -2.247)" fill={ color } fillRule="evenodd"/>
        </svg>
    );
}

export const ClockAlertIcon = ({color="#eff5fd"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.564" height="16.972" viewBox="0 0 17.564 16.972">
            <g id="alarm-clock-line" transform="translate(-2.118 -2.171)">
                <path id="Trazado_950" data-name="Trazado 950" d="M28.273,3.116A3.273,3.273,0,0,0,24.1,2.759a9.105,9.105,0,0,1,4.643,4.332,3.245,3.245,0,0,0-.47-3.975Z" transform="translate(-9.534)" fill={color}/>
                <path id="Trazado_951" data-name="Trazado 951" d="M7.385,2.887A3.235,3.235,0,0,0,2.844,7.445,9.105,9.105,0,0,1,7.385,2.887Z" transform="translate(0 -0.003)" fill={color}/>
                <path id="Trazado_952" data-name="Trazado 952" d="M11.032,4.051A7.928,7.928,0,0,0,6.338,17.522L4.894,18.966a.571.571,0,0,0,.815.8l1.506-1.506a7.871,7.871,0,0,0,9.559-.045l1.552,1.552a.566.566,0,0,0,.8-.8l-1.523-1.5A7.928,7.928,0,0,0,11.032,4.051Zm1.8,14.615a6.8,6.8,0,1,1,5.855-5.855A6.8,6.8,0,0,1,12.833,18.666Z" transform="translate(-0.827 -0.793)" fill={color}/>
                <path id="Trazado_953" data-name="Trazado 953" d="M21.511,15.248l-3.432-1.7V9.11a.51.51,0,1,0-1.019,0v5.1l4,1.982a.51.51,0,0,0,.447-.917Z" transform="translate(-6.481 -2.789)" fill={color}/>
            </g>
        </svg>
    );
}

export const SucArrowIcon = ({color="#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.749" height="10.748" viewBox="0 0 10.749 10.748">
            <g id="arrow-up-short" transform="translate(-10.123 -10.123)">
                <path id="Trazado_947" data-name="Trazado 947" d="M17.643,12.375a.768.768,0,0,1,.768.768v7.676a.768.768,0,0,1-1.535,0V13.143A.768.768,0,0,1,17.643,12.375Z" transform="translate(-2.145 -0.715)" fillRule="evenodd" />
                <path id="Trazado_948" data-name="Trazado 948" d="M14.954,10.349a.768.768,0,0,1,1.087,0l4.606,4.606a.769.769,0,1,1-1.087,1.087L15.5,11.978l-4.063,4.063a.769.769,0,0,1-1.087-1.087l4.606-4.606Z" fillRule="evenodd" />
            </g>
        </svg>
    );
}

export const SegmentarIcon = ({color="#535af1"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.25" height="16.896" viewBox="0 0 23.25 16.896">
            <path id="list" fill={ color } d="M0,19.063H4.187V23.25H0Zm0,0H4.187V23.25H0Zm0-6.354H4.187V16.9H0ZM0,6.354H4.187v4.187H0V6.354ZM6.153,19.063h17.1V23.25H6.153Zm0,0,17.1,4.187H6.153Zm0-6.354h17.1V16.9H6.153Zm0-6.354h17.1v4.187H6.153V6.354Z" transform="translate(0 -6.354)"/>
        </svg>
    )
}

export const TableCheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.423" viewBox="0 0 18 13.423">
            <g id="Grupo_1576" data-name="Grupo 1576" transform="translate(0 13.423)">
                <path id="Trazado_872" data-name="Trazado 872" d="M6.114-.264.264-6.114a.9.9,0,0,1,0-1.273L1.536-8.659a.9.9,0,0,1,1.273,0L6.75-4.718l8.441-8.441a.9.9,0,0,1,1.273,0l1.273,1.273a.9.9,0,0,1,0,1.273L7.386-.264A.9.9,0,0,1,6.114-.264Z" fill="#5359f1"/>
            </g>
        </svg>
    );
}

export const TableCloseIcon = () => {
    return (
        <svg className="no-color-change" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="Grupo_1576" data-name="Grupo 1576" transform="translate(0 16)">
                <path id="Trazado_872" data-name="Trazado 872" d="M15.854-3.146h0L11-8l4.854-4.854h0a.5.5,0,0,0,.114-.178.5.5,0,0,0-.114-.528l-2.293-2.293a.5.5,0,0,0-.529-.114.5.5,0,0,0-.178.114h0L8-11,3.146-15.854a.5.5,0,0,0-.178-.114.5.5,0,0,0-.529.114L.146-13.561a.5.5,0,0,0-.114.529.5.5,0,0,0,.114.178h0L5-8,.146-3.146h0a.5.5,0,0,0-.114.178.5.5,0,0,0,.114.529L2.439-.146a.5.5,0,0,0,.529.114.5.5,0,0,0,.178-.114h0L8-5,12.854-.146h0a.5.5,0,0,0,.178.114.5.5,0,0,0,.529-.114l2.293-2.293a.5.5,0,0,0,.114-.529A.5.5,0,0,0,15.854-3.146Z" fill="#f57e8b"/>
            </g>
        </svg>
    );
}

export const PayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <g id="Grupo_1885" data-name="Grupo 1885" transform="translate(-817.181 -217.614)">
                <rect id="Rectángulo_294" data-name="Rectángulo 294" width="17" height="17" rx="8.5" transform="translate(817.181 217.614)" fill="#535af1"/>
                <path id="Trazado_219" data-name="Trazado 219" d="M-11587.143-9919.9l2.309,2.309,4.8-4.8" transform="translate(12409.523 10145.623)" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="2"/>
            </g>
        </svg>
    );
}

export const DeliveredFalseIcon = () => {
    return (
        <svg id="deliv_false" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
            <g id="Checkbox" transform="translate(-0.329 0.067)">
                <rect id="Area" width="18" height="19" transform="translate(0.329 -0.067)" fill="#d6e8fe"/>
                <path id="Check" d="M0,0V4.739H8.651" transform="translate(4.512 9.679) rotate(-45)" fill="none" opacity="0"/>
            </g>
        </svg>
    );
}

export const DeliveredPartialIcon = () => {
    return (
        <svg id="deliv_partial" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
            <g id="Grupo_1883" data-name="Grupo 1883" transform="translate(-925.438 -258.723)">
                <g id="Checkbox" transform="translate(925.108 258.79)">
                <rect id="Area" width="18" height="19" transform="translate(0.329 -0.067)" fill="#535af1"/>
                <path id="Check" d="M0,0V4.739H8.651" transform="translate(4.512 9.679) rotate(-45)" fill="none" opacity="0"/>
                </g>
                <line id="Línea_451" data-name="Línea 451" x2="8" transform="translate(930.437 268.042)" fill="none" stroke="#fff" strokeWidth="2"/>
            </g>
        </svg>
    );
}

export const DeliveredIcon = () => {
    return (
        <svg id="deliv" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
            <g id="Grupo_1886" data-name="Grupo 1886" transform="translate(-925.438 -216.75)">
                <g id="Checkbox" transform="translate(925.108 216.817)">
                <rect id="Area" width="18" height="19" transform="translate(0.329 -0.067)" fill="#535af1"/>
                <path id="Check" d="M0,0V4.739H8.651" transform="translate(4.512 9.679) rotate(-45)" fill="none" opacity="0"/>
                </g>
                <path id="Trazado_772" data-name="Trazado 772" d="M-11587.143-9918.75l3.376,3.376,7.025-7.026" transform="translate(12516.379 10145.137)" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
        </svg>
    );
}

export const RegisterFormOk = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.354" height="15.178" viewBox="0 0 20.354 15.178">
            <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M6.913,19.457.3,12.842A1.018,1.018,0,0,1,.3,11.4L1.737,9.964a1.018,1.018,0,0,1,1.439,0L7.633,14.42l9.545-9.545a1.018,1.018,0,0,1,1.439,0l1.439,1.439a1.018,1.018,0,0,1,0,1.439l-11.7,11.7A1.018,1.018,0,0,1,6.913,19.457Z" transform="translate(0 -4.577)" fill="#535af1"/>
        </svg>
    );
}

export const RegisterFormError = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.849" height="17.849" viewBox="0 0 17.849 17.849">
            <path id="Icon_metro-cross" data-name="Icon metro-cross" d="M20.257,16.267h0l-5.415-5.415,5.415-5.415h0a.559.559,0,0,0,0-.789L17.7,2.091a.559.559,0,0,0-.789,0h0L11.5,7.506,6.08,2.091h0a.559.559,0,0,0-.789,0L2.734,4.649a.559.559,0,0,0,0,.789h0l5.415,5.415L2.734,16.267h0a.559.559,0,0,0,0,.789l2.558,2.558a.559.559,0,0,0,.789,0h0L11.5,14.2l5.415,5.415h0a.559.559,0,0,0,.789,0l2.558-2.558a.559.559,0,0,0,0-.789Z" transform="translate(-2.571 -1.928)" fill="#fd7688"/>
        </svg>
    );
}

export const CreditCardIcon = ({ opacity = 1 }) => (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="step-icon" opacity={ opacity }>
        <path d="M12.6 0H1.4C0.623 0 0.00699999 0.611875 0.00699999 1.375L0 9.625C0 10.3881 0.623 11 1.4 11H12.6C13.377 11 14 10.3881 14 9.625V1.375C14 0.611875 13.377 0 12.6 0ZM12.6 9.625H1.4V5.5H12.6V9.625ZM12.6 2.75H1.4V1.375H12.6V2.75Z" fill="#575757"/>
    </svg>
);

export const CopyIcon = () => (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> 
        <path d="M16 9V7C16 4.79086 14.2091 3 12 3H5C2.79086 3 1 4.79086 1 7V16C1 18.2091 2.79086 20 5 20H7" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> 
        <rect x="8" y="8" width="13" height="13" rx="2" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> 
    </svg>
)
export const StatsIcon = ({ color = 'currentColor' }) => {
    return (
        <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M3 3V19C3 20.1046 3.89543 21 5 21H21" 
                stroke={color} 
                strokeWidth="2" 
                strokeLinecap="round"
            />
            <path 
                d="M7 14L11 10L15 14L21 8" 
                stroke={color} 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}; 
export const TemplateIcon = () => {
    return (
        <svg></svg>
    );
}

