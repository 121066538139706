import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import Calendar from 'react-calendar';
import { barberiaNow } from '../../helpers/calendar';
import { useSelector } from 'react-redux';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const DropdownDate = ({ 
    selectDate, 
    setSelectDate, 
    fechaCalendar, 
    setFechaCalendar, 
    setTimeCalendar,
    disabled = false 
}) => {
    const { colores } = useSelector(state => state.ui);
    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        if (selectDate.visible && !disabled) {
            setVisibility(true);
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const dayClicked = () => {
        if (!disabled) {
            setTimeCalendar(new Date(1972,10,13,0,0));
            (visibility) ? hideDropdown() : showDropdown();
        }
    };

    const dateChange = (e) => {
        if (!disabled) {
            setFechaCalendar(e);
        }
    };

    useOutsideClick(dropdownMenuRef, hideDropdown, visibility);

    return (
        <div className="brb-select">
            <div
                className={`brb-select-container ${selectDate.visible ? 'visible' : ''} ${visibility ? 'active' : ''}`}
                onClick={() => !disabled && (visibility ? hideDropdown() : showDropdown())}
                style={{ cursor: disabled ? 'default' : 'pointer' }}
            >
                <p>{moment(fechaCalendar).format("DD-MM-YYYY")}</p>
                {!disabled && (
                    <div className="right-zone">
                        <p></p>
                        <span className="select-arrow select-arrow-down"></span>
                    </div>
                )}
            </div>
            {!disabled && (
                <div 
                    ref={dropdownMenuRef} 
                    className={`brb-select-options select-date ${visibility ? 'active' : ''}`}
                    style={{ backgroundColor: colores.color6 }}
                >
                    <Calendar
                        onClickDay={() => dayClicked()}
                        onChange={dateChange}
                        value={fechaCalendar}
                        minDate={moment(barberiaNow()).toDate()}
                        locale={`es-ES`}
                        className={`${(selectDate.active) ? 'visible' : ''}`}
                    />
                </div>
            )}
        </div>
    )
}
