import React from 'react';

export const DropdownFilter = ({ options, selected, onChange }) => {
    const handleOptionClick = (optionId) => {
        const newSelected = selected.includes(optionId)
            ? selected.filter(id => id !== optionId)
            : [...selected, optionId];
        onChange(newSelected);
    };

    return (
        <div className="dropdown-filter">
            {options.map(option => (
                <div 
                    key={option.id} 
                    className="filter-option"
                    onClick={() => handleOptionClick(option.id)}
                >
                    <input
                        type="checkbox"
                        checked={selected.includes(option.id)}
                        readOnly
                    />
                    <span>{option.label}</span>
                </div>
            ))}
        </div>
    );
}; 