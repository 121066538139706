import { types } from "../types/types";
import { fetchConToken } from '../helpers/fetch';
import { closeLoading, openModal, openModalBottom } from './ui';

export const startNewCita = (values, swal = true) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken('citas/cliente/crear-cita', values, 'POST');
            const body = await resp.json();
            if (body.ok) {
                if (swal) {
                    const datos = {
                        text: ['Su reserva se ha realizado con éxito'],
                        timeOut: 3
                    }
                    dispatch(openModalBottom(datos));
                }
                dispatch(addNewCita(body.cita._id, body.cita));
                if (body.cita2) {
                    dispatch(addNewCita(body.cita2._id, body.cita2));
                }
            } else {
                console.log('Error!!!');
                const datos = {
                    title: 'Error',
                    text: [body.msg],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
            }
        } catch (error) {
            console.log('Error!!!');
            console.log(error);
            const datos = {
                title: 'Error',
                text: ['Ha habido un error'],
                buttonText: 'Cerrar'
            }
            dispatch(openModal(datos));
        }
    }
};

export const activeCita = (_id, cita) => ({
    type: types.citasActive,
    payload: {
        _id,
        ...cita
    }
});

export const unactiveCita = () => ({
    type: types.citasUnactive
});

export const addNewCita = (_id, cita) => ({
    type: types.citasAddNew,
    payload: {
        _id,
        ...cita
    }
});

export const addNewDobleCita = (id, cita, id2, cita2) => ({
    type: types.citasAddNewDouble,
    payload: {
        id,
        ...cita,
        id2,
        ...cita2
    }
});

export const startLoadingCitas = (empresa) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('citas/empresa', { empresa }, 'POST');
            const body = await resp.json();
            dispatch(setCitas(body.citas));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingCitasMes = (empresa, fecha) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('citas/mes', { empresa, fecha }, 'POST');
            const body = await resp.json();
            dispatch(setCitas(body.citas));
        } catch (error) {
            console.log(error);
        }
    }
};

export const getCita = (idCita, empresa) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`citas/cita/${idCita}`, { empresa }, 'POST');
            const body = await resp.json();
            if (body.cita._id) {
                dispatch(activeCita(body.cita._id, body.cita));
            }
        } catch (error) {
            console.log('Hay error');
            console.log(error);
        }
    }
};

export const getCitas = (idCliente, empresa) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`citas/historialcitasusuario`, { id: idCliente, empresa }, 'POST');
            const body = await resp.json();
            dispatch(setCitasHistoria(body.citas));
            //dispatch(startLoadingHistoriaClienteCitas(body));
        } catch (error) {
            console.log('Hay error');
            console.log(error);
        }
    }
};

export const startLoadingListadoClientesEmpresa = (empresa) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`citas/listadoclientesempresa`, { empresa }, 'POST');
            const body = await resp.json();
            dispatch(setListadoClientesEmpresa(body.resultado));
        } catch (error) {
            console.log('Hay error');
            console.log(error);
        }
    }
}

export const setCitas = (citas) => ({
    type: types.citasLoad,
    payload: citas
});

export const setCitasHistoria = (citas) => ({
    type: types.citasLoadHistory,
    payload: citas
});

export const setListadoClientesEmpresa = (listado) => ({
    type: types.listadoClientesEmpresa,
    payload: listado
});

export const startSaveCita = (cita) => {
    //console.log( 'startSaveCita' );
    return async (dispatch, getState) => {
        try {
            console.log('cita', cita);
            const copy = {
                ...cita,
                cita: {
                    ...cita.cita,
                    servicio: cita.servicios[0]
                }
            }
            const resp = await fetchConToken(`citas/${cita._id}`, copy, 'PUT');
            const body = await resp.json();

            if (body.ok) {
                dispatch(refreshCita(cita._id, body.cita));

                const datos = {
                    text: ['Su reserva ha sido modificada'],
                    timeOut: 3
                }

                dispatch(openModalBottom(datos));
                dispatch(closeLoading());
            } else {
                console.log('Error', body);
                const datos = {
                    title: 'Error',
                    text: [body.msg],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
                dispatch(closeLoading());
            }
        } catch (error) {
            console.log(error);
            dispatch(closeLoading());
        }
    }
};

export const startSaveComentariosCita = (idCita, comentarios, swal = true) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken(`citas/comentarios/${idCita}`, { comentarios }, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(refreshCita(idCita, body.cita));
                const datos = {
                    text: ['Comentarios guardados'],
                    timeOut: 3
                }
                dispatch(openModalBottom(datos));
            } else {
                console.log(body);
                const datos = {
                    title: 'Error',
                    text: [body.msg],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
            }


        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshCita = (id, cita) => ({
    type: types.citasUpdated,
    payload: {
        ...cita
    }
});

export const citaStartDelete = (id, empresa, estadoCita) => {
    return async (dispatch, getState) => {

        try {
            const resp = await fetchConToken(`citas/${id}`, { empresa, estadoCita }, 'DELETE');
            const body = await resp.json();

            if (body.ok) {
                const datos = {
                    text: ['La reserva ha sido eliminada'],
                    timeOut: 3
                }
                dispatch(openModalBottom(datos));
                dispatch(citaDeleted(id));
            } else {
                const datos = {
                    title: 'Error',
                    text: [body.msg],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
            }


        } catch (error) {
            console.log(error);
        }
    }
}

const citaDeleted = (id) => ({
    type: types.citasDelete,
    payload: id
});

export const citaLogout = () => ({
    type: types.citasLogoutCleaning
});

export const updateCita = (cita) => ({
    type: types.citaUpdated,
    payload: cita
});

export const startUpdateCita = (citaData) => {
    return async (dispatch) => {
        try {
            const resp = getCita(citaData._id);
            const body = await resp.json();

            if (body.ok) {
				dispatch(refreshCita(citaData._id, body.cita));                
                return body;
            }
        } catch (error) {
            console.error('Error al actualizar la cita:', error);
        }
    };
};
