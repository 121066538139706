
export const types = {
    
    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetTitle: '[UI] Set title',
    uiSetDarkHeader: '[UI] Set Dark Header',
    uiSetAdminHeader: '[UI] Set Admin Header',

    uiSetError: '[UI] Set Error',
    uiRemoverror: '[UI] Remove Error',
    
    
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    uiToggleMenu: '[UI] Toggle menu',
    uiOpenLogin: '[UI] Open login',
    uiCloseLogin: '[UI] Close login',
    uiOpenRegister: '[UI] Open register',
    uiCloseRegister: '[UI] Close register',
    uiOpenRenew: '[UI] Open renew',
    uiCloseRenew: '[UI] Close renew',
    uiOpenModal: '[UI] Open modal',
    uiCloseModal: '[UI] Close modal',
    uiOpenLoading: '[UI] Open loading',
    uiCloseLoading: '[UI] Close loading',
    uiOpenModalBottom: '[UI] Open modal bottom',
    uiCloseModalBottom: '[UI] Close modal bottom',
    uiClockUpdate: '[UI] Clock update',
    uiSetLanguage: '[UI] Set language',
    uiSetVersion: '[UI] Set version',
    uiSetMenuLateral: '[UI] Set menu lateral',
    uiSetMiniLateral: '[UI] Set mini lateral',
    uiSetColores: '[UI] Set colores',

    uiModalShow: '[UI] Show Modal',
    uiBodyClass: '[UI] Body Class',

    authCheckingFinish: '[auth] Finish checking login state', 
    authStartLogin: '[auth] Start login', 
    authLogin: '[auth] Login', 
    authStartRegister: '[auth] Start Register', 
    authStartTokenRenew: '[auth] Start token renew', 
    authLogout: '[auth] Logout',

    usuariosAddNew: '[Usuarios] New usuario',
    usuariosActive: '[Usuarios] Set active usuario',
    usuariosUnactive: '[Usuarios] Unactive usuario',
    usuarioLoad: '[Usuarios] Load usuario',
    usuariosLoad: '[Usuarios] Load usuarios',
    usuariosUpdated: '[Usuarios] Updated usuario',
    usuariosFileUrl: '[Usuarios] Updated image url',
    usuariosDelete: '[Usuarios] Delete usuario',
    usuariosLogoutCleaning: '[Usuarios] Logout Cleaning',

    empresasAddNew: '[Empresas] New empresa',
    empresasActive: '[Empresas] Set active empresa',
    empresasUnactive: '[Empresas] Unactive empresa',
    empresasLoad: '[Empresas] Load empresas',
    empresasUpdated: '[Empresas] Updated empresa',
    empresasFileUrl: '[Empresas] Updated image url',
    empresasDelete: '[Empresas] Delete empresa',
    empresasLogoutCleaning: '[Empresas] Logout Cleaning',

    sucursalesAddNew: '[Sucursales] New sucursal',
    sucursalesActive: '[Sucursales] Set active sucursal',
    sucursalesUnactive: '[Sucursales] Unactive sucursal',
    sucursalesLoad: '[Sucursales] Load sucursales',
    sucursalesUpdated: '[Sucursales] Updated sucursal',
    sucursalesFileUrl: '[Sucursales] Updated image url',
    sucursalesDelete: '[Sucursales] Delete sucursal',
    sucursalesLogoutCleaning: '[Sucursales] Logout Cleaning',
    
    vacacionesAddNew: '[Vacaciones] New vacaciones',
    vacacionesActive: '[Vacaciones] Set active vacaciones',
    vacacionesUnactive: '[Vacaciones] Unactive vacaciones',
    vacacionesLoad: '[Vacaciones] Load vacaciones',
    vacacionesUpdated: '[Vacaciones] Updated vacaciones',
    vacacionesFileUrl: '[Vacaciones] Updated image url',
    vacacionesDelete: '[Vacaciones] Delete vacaciones',
    vacacionesLogoutCleaning: '[Vacaciones] Logout Cleaning',

    serviciosAddNew: '[Servicios] New servicio',
    serviciosActive: '[Servicios] Set active servicio',
    serviciosUnactive: '[Servicios] Unactive servicio',
    serviciosLoad: '[Servicios] Load servicios',
    serviciosUpdated: '[Servicios] Updated servicio',
    serviciosFileUrl: '[Servicios] Updated image url',
    serviciosDelete: '[Servicios] Delete servicio',
    serviciosLogoutCleaning: '[Servicios] Logout Cleaning',
    
    profesionalesAddNew: '[Profesionales] New profesional',
    profesionalesActive: '[Profesionales] Set active profesional',
    profesionalesUnactive: '[Profesionales] Unactive profesional',
    profesionalesLoad: '[Profesionales] Load profesionales',
    profesionalesUpdated: '[Profesionales] Updated profesional',
    profesionalesFileUrl: '[Profesionales] Updated image url',
    profesionalesDelete: '[Profesionales] Delete profesional',
    profesionalesLogoutCleaning: '[Profesionales] Logout Cleaning',

    horariosAddNew: '[Horarios] New horario',
    horariosActive: '[Horarios] Set active horario',
    horariosUnactive: '[Horarios] Unactive horario',
    horariosLoad: '[Horarios] Load horarios',
    horariosUpdated: '[Horarios] Updated horario',
    horariosFileUrl: '[Horarios] Updated image url',
    horariosDelete: '[Horarios] Delete horario',
    horariosLogoutCleaning: '[Horarios] Logout Cleaning',
    
    citasAddNew: '[Citas] New cita',
    citasAddNewDouble: '[Citas] New cita doble',
    citasActive: '[Citas] Set active cita',
    citasUnactive: '[Citas] Unactive cita',
    citasLoad: '[Citas] Load citas',
    citasLoadHistory: '[Citas] Load citas',
    citasUpdated: '[Citas] Updated citas',
	citaUpdated: '[Citas] Updated cita',
    citasFileUrl: '[Citas] Updated image url',
    citasDelete: '[Citas] Delete cita',
    citasLogoutCleaning: '[Citas] Logout Cleaning',

    promocionesAddNew: '[Promociones] New promocion',
    promocionesActive: '[Promociones] Set active promocion',
    promocionesUnactive: '[Promociones] Unactive promocion',
    promocionesLoad: '[Promociones] Load promociones',
    promocionesUpdated: '[Promociones] Updated promocion',
    promocionesDelete: '[Promociones] Delete promocion',
    promocionesLogoutCleaning: '[Promociones] Logout Cleaning',
    
    productosAddNew: '[Productos] New producto',
    productosActive: '[Productos] Set active producto',
    productosUnactive: '[Productos] Unactive producto',
    productosLoad: '[Productos] Load productos',
    productosUpdated: '[Productos] Updated producto',
    productosDelete: '[Productos] Delete producto',
    productosLogoutCleaning: '[Productos] Logout Cleaning',
    
    comentariosAddNew: '[Comentarios] New comentario',
    comentariosActive: '[Comentarios] Set active comentario',
    comentariosUnactive: '[Comentarios] Unactive comentario',
    comentariosLoad: '[Comentarios] Load comentarios',
    comentariosUpdated: '[Comentarios] Updated comentario',
    comentariosDelete: '[Comentarios] Delete comentario',
    comentariosLogoutCleaning: '[Comentarios] Logout Cleaning',

    sectoresAddNew: '[Sectores] New sectores',
    sectoresActive: '[Sectores] Set active sectores',
    sectoresUnactive: '[Sectores] Unactive sectores',
    sectoresLoad: '[Sectores] Load sectores',
    sectoresUpdated: '[Sectores] Updated sectores',
    sectoresFileUrl: '[Sectores] Updated image url',
    sectoresDelete: '[Sectores] Delete sectores',
    sectoresLogoutCleaning: '[Sectores] Logout Cleaning',

    planesAddNew: '[Planes] New planes',
    planesActive: '[Planes] Set active planes',
    planesUnactive: '[Planes] Unactive planes',
    planesLoad: '[Planes] Load planes',
    planesUpdated: '[Planes] Updated planes',
    planesFileUrl: '[Planes] Updated image url',
    planesDelete: '[Planes] Delete planes',
    planesLogoutCleaning: '[Planes] Logout Cleaning',
    
    variantesAddNew: '[Variantes] New variante',
    variantesActive: '[Variantes] Set active variante',
    variantesUnactive: '[Variantes] Unactive variante',
    variantesLoad: '[Variantes] Load variantes',
    variantesUpdated: '[Variantes] Updated variante',
    variantesDelete: '[Variantes] Delete variante',
    variantesLogoutCleaning: '[Variantes] Logout Cleaning',
    
    categoriasAddNew: '[Categorias] New categoria',
    categoriasActive: '[Categorias] Set active categoria',
    categoriasUnactive: '[Categorias] Unactive categoria',
    categoriasLoad: '[Categorias] Load categorias',
    categoriasUpdated: '[Categorias] Updated categoria',
    categoriasDelete: '[Categorias] Delete categoria',
    categoriasLogoutCleaning: '[Categorias] Logout Cleaning',
    
    pedidosAddNew: '[Pedidos] New pedido',
    pedidosActive: '[Pedidos] Set active pedido',
    pedidosUnactive: '[Pedidos] Unactive pedido',
    pedidosLoad: '[Pedidos] Load pedidos',
    pedidosUpdated: '[Pedidos] Updated pedido',
    pedidosDelete: '[Pedidos] Delete pedido',
    pedidosLogoutCleaning: '[Pedidos] Logout Cleaning',

    reservaSave: '[Reservas] New reserva',
    reservaDelete: '[Reservas] Delete reserva',

    listadoClientesEmpresa: '[ListadoClientesEmpresa] Load listadoClientesEmpresa',

    textLegalNew: '[TextLegal] New textLegal',
    textLegalLoad: '[TextLegal] Load textLegal',
    textLegalUpdated: '[TextLegal] Updated textLegal',

    clienteSaldosLoad: '[ClienteSaldos] Load clienteSaldos',
}