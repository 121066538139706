import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import { startLoadingCategorias } from '../actions/categorias';
import { startLoadingEmpresaById, startLoadingEmpresas } from '../actions/empresas';
import { startLoadingHorarios } from '../actions/horarios';
import { startLoadingPedidosPendientes } from '../actions/pedidos';
import { startLoadingPlanes } from '../actions/planes';
import { startLoadingProductos } from '../actions/productos';
import { startLoadingProfesionales } from '../actions/profesionales';
import { startLoadingPromociones } from '../actions/promociones';
import { startLoadingSectores } from '../actions/sectores';
import { startLoadingServicios } from '../actions/servicios';
import { startLoadingSucursales } from '../actions/sucursales';
import { setAdminHeader, setColores, setDarkHeader, startCheckingVersion } from '../actions/ui';
import { startLoadingVariantes } from '../actions/variantes';

import { MenuLateral } from '../components/gestion/MenuLateral';
import { Navbar } from '../components/main/Navbar';
import { Admin } from '../pages/admin/Admin';
import { Clientes } from '../pages/clientes/Clientes';
import { Cliente } from '../pages/clientes/components/Cliente';
import { Configuracion } from '../pages/configuracion/Configuracion';
import { Empresa } from '../pages/empresas/components/Empresa';
import { Empresas } from '../pages/empresas/Empresas';
import { Idiomas } from '../pages/idiomas/Idiomas';
import Notificacion from '../pages/notificaciones/components/Notificacion';
import { Notificaciones } from '../pages/notificaciones/Notificaciones';
import { Plan } from '../pages/planes/Plan';
import { Planes } from '../pages/planes/Planes';
import { Descuento } from '../pages/preciosEspeciales/components/descuentos/Descuento';
import { PreciosEspeciales } from '../pages/preciosEspeciales/PreciosEspeciales';
import { Profesional } from '../pages/profesionales/components/Profesional';
import { Profesionales } from '../pages/profesionales/Profesionales';
import { Categoria } from '../pages/puntoVenta/components/categorias/Categoria';
import { Producto } from '../pages/puntoVenta/components/productos/Producto';
import { Servicio } from '../pages/puntoVenta/components/servicios/Servicio';
import { Variante } from '../pages/puntoVenta/components/variantes/Variante';
import { PuntoVenta } from '../pages/puntoVenta/PuntoVenta';
import { Reservas } from '../pages/reservas/Reservas';
import { Sectores } from '../pages/sectores/Sectores';
import { Sucursal } from '../pages/sucursales/components/Sucursal';
import { Sucursales } from '../pages/sucursales/Sucursales';
import { Ventas } from '../pages/ventas/Ventas';

const authorized = ['SUPERADMIN_ROLE', 'ADMIN_ROLE', 'BUSINESS_ROLE'];
// const usarSockets = process.env.REACT_SOCKETS;

export const DashboardRoutes = ({history}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const { empresas } = useSelector(state => state.empresas );

    const [menuActive, setMenuActive] = useState('reservas');
    const [miniLateral, setMiniLateral] = useState(true);

    const currentUid = useMemo( () => { 
        return auth.uid;
    }, [auth.uid]);    

    useEffect(() => {
        dispatch( startCheckingVersion() );
        dispatch( setDarkHeader(true) );
        dispatch( setAdminHeader(true) );
        
        if( currentUid ){
            if( authorized.indexOf( auth.role ) > -1 ){
                //dispatch( startLoadingUsuariosEmpresa( auth.empresa ));
            }
            if(( auth.role === 'SUPERADMIN_ROLE' || auth.role === 'ADMIN_ROLE') && window.location.hostname == process.env.REACT_APP_ADMIN_URL){
                setMenuActive('empresas');                
                dispatch( startLoadingEmpresas() );
            } else {
                dispatch( startLoadingEmpresaById( auth.empresa ) );
            }
            dispatch( startLoadingSucursales( auth.empresa ) );
            dispatch( startLoadingServicios( auth.empresa ) );
            dispatch( startLoadingProfesionales( auth.empresa ) );
            dispatch( startLoadingHorarios( auth.empresa ) );
            dispatch( startLoadingPromociones( auth.empresa ) );
            dispatch( startLoadingProductos( auth.empresa ) );
            dispatch( startLoadingPedidosPendientes( auth.empresa ) );
            dispatch( startLoadingVariantes( auth.empresa ) );
            dispatch( startLoadingCategorias( auth.empresa ) );
            dispatch( startLoadingSectores() );
            dispatch( startLoadingPlanes() );
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth._id]);

    useEffect(() => {
        if( empresas.length > 0 ){
            empresas.forEach(empresa => {
                if( empresa._id === auth.empresa ){
                    const newColores = {
                        colorPrincipal: empresa.colorPrincipal,
                        colorSecundario: empresa.colorSecundario,
                        color3: empresa.color3,
                        color4: empresa.color4,
                        color5: empresa.color5,
                        color6: empresa.color6,
                        color7: empresa.color7
                    };
                    dispatch( setColores( newColores ));
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresas]);

    /* useEffect(() => {
      console.log( 'currentUid', currentUid );
    }, [currentUid]) */
    
    

    return (
        <>
            <Navbar />
            <div className="gestion">
                <MenuLateral
                    history={ history }
                    menuActive = { menuActive }
                    setMenuActive = { setMenuActive }
                    miniLateral = { miniLateral }
                    setMiniLateral = { setMiniLateral }
                />
                {
                    (auth.role === 'SUPERADMIN_ROLE' || auth.role === 'ADMIN_ROLE') && window.location.hostname == process.env.REACT_APP_ADMIN_URL
                    ?
                    <Switch>
                        <Route exact path="/empresas" component={ Empresas } />
                        <Route exact path="/empresa/:empresaId" component={ Empresa } />
                        <Route exact path="/idiomas" component={ Idiomas } />
                        <Route exact path="/sectores" component={ Sectores } />
                        <Route exact path="/planes" component={ Planes } />
                        <Route exact path="/plan/:planId" component={ Plan } />
                        <Redirect to="/empresas" />
                    </Switch>
                    :
                    <Switch>
                        <Route exact path="/reservas" component={ Reservas } />
                        <Route exact path="/profesionales" component={ Profesionales } />
                        <Route exact path="/profesional/:profId" component={ Profesional } />
                        <Route exact path="/clientes" component={ Clientes } />
                        <Route exact path="/cliente/:clienteId" component={ Cliente } />
                        <Route exact path="/punto-venta/:tab?" component={ PuntoVenta } />
                        <Route exact path="/servicio/:servicioId" component={ Servicio } />
                        <Route exact path="/producto/:productoId" component={ Producto } />
                        <Route exact path="/variante/:varianteId" component={ Variante } />
                        <Route exact path="/categoria/:categoriaId" component={ Categoria } />
                        <Route exact path="/precios-especiales/:tab?" component={ PreciosEspeciales } />
                        <Route exact path="/descuento/:descuentoId" component={ Descuento } />
                        <Route exact path="/notificaciones" component={ Notificaciones } />
                        <Route exact path="/notificacion/:notificacionId" component={ Notificacion } />
                        <Route exact path="/configuracion" component={ Configuracion } />
                        <Route exact path="/admin" component={ Admin } />
                        <Route exact path="/sucursales" component={ Sucursales } />
                        <Route exact path="/ventas" component={ Ventas } />
                        <Route exact path="/sucursal/:sucursalId" component={ Sucursal } />
                        <Redirect to="/reservas" />
                    </Switch>
                }
            </div>
        </>
    )
}
