import { fetchConToken } from '../helpers/fetch';

// Definimos los tipos de acciones
export const types = {
    ventaAdded: '[Venta] Added',
};

// Acción para añadir una venta
export const addVenta = (venta) => ({
    type: types.ventaAdded,
    payload: venta
});

// Acción asíncrona para guardar la venta
export const startSaveVenta = (ventaData) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken('ventas', ventaData, 'POST');
            const body = await resp.json();

            if (body.ok) {
                return { ok: true, venta: body.venta };
            } else {
                throw new Error(body.msg);
            }
        } catch (error) {
            console.error('Error al guardar la venta:', error);
            return { ok: false, error };
        }
    }
};

export const startUpdateVenta = (ventaId, ventaData) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken(`ventas/${ventaId}`, ventaData, 'PUT');
            const body = await resp.json();

            if (body.ok) {
                return { ok: true, venta: body.venta };
            } else {
                throw new Error(body.msg);
            }
        } catch (error) {
            console.error('Error al actualizar la venta:', error);
            return { ok: false, error };
        }
    }
}; 