import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { SocketContext } from '../../context/SocketContext';

import { CustomCalendar } from './components/CustomCalendar';
import { Filters } from './components/Filters';
import { ModalDeleteCita } from './components/ModalDeleteCita';
import { ModalReserva } from './components/ModalReserva';
import { setBodyClass, setMenuLateral } from '../../actions/ui';
import { startLoadingVacacionesEmpresa } from '../../actions/vacaciones';
import { citaLogout, startLoadingCitasMes } from '../../actions/citas';
import { startLoadingEmpresaById } from '../../actions/empresas';
import { startLoadingSucursales } from '../../actions/sucursales';
import { startLoadingServicios } from '../../actions/servicios';
import { startLoadingProfesionales } from '../../actions/profesionales';
import { startLoadingHorarios } from '../../actions/horarios';
import { startLoadingPromociones } from '../../actions/promociones';
import { startLoadingProductos } from '../../actions/productos';
import { YlmModalTwoButtons } from '../../components/main/YlmModalTwoButtons';
import { pedidoLogout, startLoadingPedidosPendientes } from '../../actions/pedidos';
import { ModalVentas } from './components/ModalVentas';

const usarSockets = process.env.REACT_SOCKETS;
//const timezone = process.env.REACT_APP_TIMEZONE;

export const Reservas = () => {

    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);
    const { empresa } = useSelector(state => state.auth);
    const { sucursales } = useSelector(state => state.sucursales);
    const { profesionales } = useSelector(state => state.profesionales);
    const { citas, active: citaActive } = useSelector(state => state.citas);
	
	

    const [firstTime, setFirstTime] = useState(false);
    const [arrSucursales, setArrSucursales] = useState([]);
    const [arrProfesionales, setArrProfesionales] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [miniLateral, setMiniLateral] = useState(true);
    const [miniFilters, setMiniFilters] = useState(true);
    const [activeModalReserva, setActiveModalReserva] = useState(false);
    const [activeModalDeleteCita, setActiveModalDeleteCita] = useState(false);
    const [agendaActive, setAgendaActive] = useState(true);
    const [estadoCita, setEstadoCita] = useState("");

    const [editCita, setEditCita] = useState(false);
    const { socket } = useContext(SocketContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isRecharging, setIsRecharging] = useState(false);

    const myTimer = useRef(null);

    const [dataModal, setDataModal] = useState({
        title: '¿Modificar cita?',
        text: ['¿Estás seguro de modificar esta cita?'],
        buttonText: 'Estoy seguro',
        button2Text: 'Cancelar'
    });
    const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);
    const [modalResponse, setModalResponse] = useState(0);
    const [modalWaiting, setModalWaiting] = useState({});
    const [activeModalVentas, setActiveModalVentas] = useState(false);	

    useEffect(() => {
        if (empresa) {
            if (usarSockets) {
                socket?.emit('obtener-citas-mes', { empresa, fecha: moment().toISOString() });
            } else {
                dispatch(startLoadingPedidosPendientes(empresa));
                dispatch(startLoadingCitasMes(empresa, moment().toISOString()));
            }
        }

        return () => {
            dispatch(pedidoLogout());
            dispatch(citaLogout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
     	function startTimer() {
            myTimer.current = setInterval(() => {				
                dispatch(startLoadingPedidosPendientes(empresa));
                checkModalReserva();
            }, (60000));
        }

        startTimer();

        return () => {
            clearInterval(myTimer.current);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, activeModalReserva]);

    const checkModalReserva = () => {
        if (!activeModalReserva) {
            cargaDatos();
        }
    }

    useEffect(() => {
        dispatch(setBodyClass('reservar'));
        dispatch(setMenuLateral('reservas'));
        dispatch(startLoadingVacacionesEmpresa(empresa));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (modalResponse === 100) {
            dispatch(startLoadingCitasMes(empresa, selectedDate.toISOString()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalResponse])



    useEffect(() => {
        if (!firstTime && sucursales.length > 0 && profesionales.length > 0) {
            processSucursales();
            processProfesionales();
            setFirstTime(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sucursales, profesionales, firstTime]);

    const cargaDatos = () => {
        if (!usarSockets) {
            setIsRecharging(true);
            // console.log( 'recargando', selectedDate.toISOString() );
            dispatch(startLoadingEmpresaById(empresa));
            dispatch(startLoadingSucursales(empresa));
            dispatch(startLoadingServicios(empresa));
            dispatch(startLoadingProfesionales(empresa));
            dispatch(startLoadingHorarios(empresa));
            dispatch(startLoadingCitasMes(empresa, selectedDate.toISOString()));
            dispatch(startLoadingPromociones(empresa));
            dispatch(startLoadingProductos(empresa));
            dispatch(startLoadingVacacionesEmpresa(empresa));
            //dispatch( startLoadingUsuariosEmpresa( empresa ));
			
			
        }
    };

    const processSucursales = () => {
        let newArray = [];
        sucursales.map(sucursal => {
            const item = {
                sucursal,
                selected: true
            }
            newArray = [...newArray, item];
            return sucursal;
        });
        setArrSucursales(newArray);
    };

    const processProfesionales = () => {
        let newArray = [];
        profesionales.map((profesional, idx) => {
            const item = {
                profesional,
                idx: idx + 1,
                selected: true
            }
            newArray = [...newArray, item];
            return profesional;
        });
        setArrProfesionales(newArray);
    };

	const handlePayment = () => {
        setActiveModalVentas(true);
        setActiveModalReserva(false);
    };

    const handleVentasClose = () => {
        setActiveModalVentas(false);
        setActiveModalReserva(true);
    };

    return (
        <>
            <Filters
                arrSucursales={arrSucursales}
                setArrSucursales={setArrSucursales}
                arrProfesionales={arrProfesionales}
                setArrProfesionales={setArrProfesionales}
                miniLateral={miniLateral}
                setMiniLateral={setMiniLateral}
                miniFilters={miniFilters}
                setMiniFilters={setMiniFilters}
                agendaActive={agendaActive}
            />
            <div
                className={`calendar ${agendaActive ? '' : 'big'}`}
                style={{
                    '--colorPrincipal': colores.colorPrincipal,
                    '--colorSecundario': colores.colorSecundario,
                    '--color3': colores.color3,
                    '--color4': colores.color4,
                    '--color5': colores.color5,
                    '--color6': colores.color6,
                    '--color7': colores.color7,
                }}
            >
                {
                    isLoading &&
                    <div
                        className='loading-citas'
                    >
                        <div className='bg-loading'></div>
                        <p
                            style={{ color: colores.colorPrincipal }}
                        >Cargando...</p>
                    </div>
                }
                <CustomCalendar
                    citas={citas}
                    arrSucursales={arrSucursales}
                    arrProfesionales={arrProfesionales}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    setActiveModalReserva={setActiveModalReserva}
                    setActiveModalDeleteCita={setActiveModalDeleteCita}
                    editCita={editCita}
                    setEditCita={setEditCita}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isRecharging={isRecharging}
                    setIsRecharging={setIsRecharging}
                    agendaActive={agendaActive}
                    setAgendaActive={setAgendaActive}
                />
            </div>
            <ModalReserva
                activeModalReserva={activeModalReserva}
                setActiveModalReserva={setActiveModalReserva}
                setActiveModalDeleteCita={setActiveModalDeleteCita}
                setEstadoCita={setEstadoCita}
                editCita={editCita}
                setEditCita={setEditCita}
                setDataModal={setDataModal}
                modalResponse={modalResponse}
                setModalResponse={setModalResponse}
                setModalTwoButtonsOpened={setModalTwoButtonsOpened}
                modalWaiting={modalWaiting}
                setModalWaiting={setModalWaiting}
                selectedDate={selectedDate}
                setActiveModalVentas={setActiveModalVentas}
            />
            <ModalDeleteCita
                activeModalDeleteCita={activeModalDeleteCita}
                setActiveModalDeleteCita={setActiveModalDeleteCita}
                estadoCita={estadoCita}
            />
            <ModalVentas 				
                activeModalVentas={activeModalVentas}
                onClose={() => setActiveModalVentas(false)}
                citaActive={citaActive}
                setActiveModalReserva={setActiveModalReserva}
				setDataModal={setDataModal}
				setModalTwoButtonsOpened={setModalTwoButtonsOpened}				
            />
            <YlmModalTwoButtons
                data={dataModal}
                setModalResponse={setModalResponse}
                modalTwoButtonsOpened={modalTwoButtonsOpened}
                setModalTwoButtonsOpened={setModalTwoButtonsOpened}
            />
            <div className={`fondo-no-click ${(activeModalReserva || activeModalVentas) ? 'active' : ''}`}></div>
        </>
    )
}
